import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Offcanvas, Button } from 'react-bootstrap';

function Rightsidebar({
  Rcontent,
  closeSidebar,
  show,
  maxWidth,
  placement,
  borderBottomLeftRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  closeOnOutsideClick // new prop to control outside click functionality
  // showCloseButton // new prop to control visibility of close button
}) {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    if (closeOnOutsideClick && show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (closeOnOutsideClick) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [closeOnOutsideClick, show, closeSidebar]);

  // useEffect(() => {
  //   const handleEscapeKey = (event) => {
  //     // if (event.key === 'Escape') {
  //     //   closeSidebar();
  //     // }
  //   };

  //   document.addEventListener('keydown', handleEscapeKey);

  //   return () => {
  //     document.removeEventListener('keydown', handleEscapeKey);
  //   };
  // }, [closeSidebar]);

  return (
    <>
      <Offcanvas
        placement={placement}
        show={show}
        onHide={() => {}}
        style={{
          backgroundColor: '#fff',
          maxWidth: maxWidth,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderTopRightRadius: borderTopRightRadius,
          borderBottomRightRadius: borderBottomRightRadius
          // position: 'relative'
        }}
      >
        {/* {showCloseButton && (
          <Button
            variant="link"
            onClick={closeSidebar}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '20px',
              color: '#000',
              zIndex: 1000
            }}
            aria-label="Close"
          >
            &times;
          </Button>
        )} */}
        <Offcanvas.Body style={{ justifyContent: 'center', display: 'flex' }} ref={sidebarRef}>
          {Rcontent}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default Rightsidebar;
Rightsidebar.defaultProps = {
  Rcontent: <div>Rcontent</div>,
  placement: 'end',
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  // showCloseButton: false,
  closeOnOutsideClick: false
};
