import React from 'react';
import { Grid, Typography } from '@mui/material';

import { usePremiumDetailsState } from '../provider/PremiumDetailsProvider';

const PremiumCustomerCard = ({ data }) => {
  const data1 = [
    {
      Coverage: data[0]?.productSubType,
      'Base Premium': `$${data[0]?.basicPremiumAmount?.toFixed(2)}`,
      'Premium With Loading': `$${data[0]?.premiumWithLoadingAmount?.toFixed(2)}`,
      'Discount %': data[0]?.finalDiscountPercentage,
      'Discount Amount': `$${data[0]?.finalDiscountAmount?.toFixed(2)}`,
      'Premium after Discount': `$${(data[0]?.premiumAfterDiscount).toFixed(2)}`,
      'Additional Rider Amount': `$${(data[0]?.afterAddition3RiderAmount).toFixed(2)}`,
      'NCD %': data[0]?.ncDpercentage,
      'NCD Amount': `$${(data[0]?.ncdAmount).toFixed(2)}`,
      Excess: `$${(data[0]?.excessAmount).toFixed(2)}`,
      'Food Delivery with GST': `$${(data[0]?.fdExtentionAmount).toFixed(2)}`
    }
  ];

  return (
    <Grid
      item
      xs={12}
      md={7.8}
      lg={7.8}
      mt={1}
      mb={2}
      // ml={2}
      style={{
        // backgroundColor: '#fff',
        boxShadow: '10px 10px 30px 10px  #F7F7F8',
        borderRadius: '15px',
        padding: '15px',
        maxWidth: '100%',
        border: '2px solid #002d8c'
      }}
    >
      <Typography variant="h5" sx={{ fontFamily: 'Light', fontWeight: 600, color: '#000' }}>
        Premium Calculation
        <Grid mt={2} style={{ border: '1px solid #ddd', width: '100%' }} />
      </Typography>
      <Grid style={{ width: '100%', borderRadius: '10px', padding: '10px', minHeight: '250px', marginTop: 5 }}>
        {data1 && (
          <Grid container sx={{ width: '100%' }}>
            {data1?.map((item, index) => (
              <React.Fragment key={index}>
                {Object?.keys(item)?.map((key) => (
                  <Grid key={key} item xs={6} md={6} lg={3} sx={{ marginTop: index === 0 ? '20px' : '50px' }}>
                    <Typography variant="body2" sx={{ fontFamily: 'Thin', fontWeight: 600, color: '#C9C9C9', fontSize: '13px' }}>
                      {key}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#000', fontFamily: 'Light', fontWeight: 600, fontSize: '13px' }}>
                      {item[key]}
                    </Typography>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PremiumCustomerCard;
