import React, { useState, useEffect } from 'react';
import '../../../../assets/fonts/Fonts.css';
import ReusableModal from 'components/modals/routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { TextField, Grid, Button, InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addWorkerSection } from 'store/reducers/premiumPolicy';
import { showSuccess, showWarning } from 'components/nudges/Alert';
import useGetCountriesList from 'modules/packages & premium/hooks/useGetCountriesList';
import { nricValidator } from 'helper/utils';
import { useMediaQuery } from '../../../../../node_modules/@mui/material/index';

const AddWorkerContent = ({ isOpen, onClose, selectedRow, isEditMode }) => {
  const dispatch = useDispatch();
  const getCountriesListData = useGetCountriesList();
  const [firstName, setFirstName] = useState(selectedRow ? selectedRow.firstName : '');
  const [lastName, setLastName] = useState(selectedRow ? selectedRow.lastName : '');
  const [nationality, setNationality] = useState(selectedRow ? selectedRow.nationality : '');
  const [dob, setDob] = useState(selectedRow ? selectedRow.dob : '');
  const [fin, setFin] = useState(selectedRow ? selectedRow.fin : '');
  const [workPermit, setWorkPermit] = useState(selectedRow ? selectedRow.workPermit : '');
  const [getUpdatedCountriesListData, setUpdatedCountriesListData] = useState([]);
  const key = selectedRow?.id || new Date().toString();

  const isYoungerThan18 = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust the age if the current date is before the birthdate in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age < 18;
  };

  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  const handleButtonClick = () => {
    if (isFutureDate(dob)) {
      showWarning('Date of Birth cannot be a future date or Current Date!');
      return;
    }

    if (isYoungerThan18(dob)) {
      showWarning('Worker must be at least 18 years old.');
      return;
    }

    if (nricValidator(fin)) {
      handleAddWorker();
    } else {
      showWarning('Please enter a valid FIN Number');
    }
  };
  const handleAddWorker = () => {
    if (lastName === '' || nationality === '' || dob === '' || fin === '' || workPermit === '') {
      showWarning('please fill all fields!');
      return;
    }

    if (isYoungerThan18(dob)) {
      showWarning('Worker must be at least 18 years old.');
      return;
    }

    if (isFutureDate(dob)) {
      showWarning('Date of Birth cannot be a future date');
      return;
    }

    const dobYear = new Date(dob).getFullYear();
    if (dobYear <= 1900) {
      showWarning('Please enter a valid date of birth ');
      return;
    }

    const workerData = {
      id: key,
      firstName,
      lastName,
      nationality,
      dob,
      fin,
      workPermit,
      isEditMode
    };
    dispatch(addWorkerSection([key, workerData]));
    showSuccess(isEditMode ? 'Worker Edited Successfully!' : 'Worker Added Successfully!');
    onClose();
  };

  const belowMobile = useMediaQuery('(max-width:767px)');

  return (
    <>
      <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column', padding: '2%' }}>
        <p style={{ margin: '1% 0% 0% 0%', fontSize: '16px' }}>{isEditMode ? 'Edit Worker' : 'Add Worker'}</p>
        <hr />
        {/* <p>{'Name of Worker(s)'}</p> */}
        <Grid container spacing={2}>
          {/* <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '2.5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px' }}
            />
          </Grid> */}

          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '5px', fontSize: '13px' }}>Full Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px', width: '100%' }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                  // marginLeft: '2px'
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '5px', fontSize: '13px' }}>Nationality</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            {/* <div style={{ padding: '5% 8% 5% 0%' }}> */}
            <DropDown
              marginTop={'-1px'}
              width={'99%'}
              heightsss={'37px'}
              height={'38px'}
              options={getCountriesListData}
              placeholder="Select Nationality"
              value={nationality}
              onValueChange={(i) => setNationality(i.label)}
            />
            {/* </div> */}
          </Grid>

          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '5px', fontSize: '13px' }}>Date of Birth</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              value={dob}
              style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px', width: '100%' }}
              onChange={(e) => setDob(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                  // marginLeft: '2px'
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '5px', fontSize: '13px' }}>FIN</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={fin}
              onChange={(e) => setFin(e.target.value.toUpperCase())}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px', width: '100%' }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                  // marginLeft: '2px'
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', marginTop: '5px', fontSize: '13px' }}>
                Work Permit Number / S Pass Number
              </InputLabel>

              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={workPermit}
              onChange={(e) => setWorkPermit(e.target.value)}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px', height: '35px', width: belowMobile?'100%':'48%' }}
              inputProps={{
                style: {
                  fontSize: '13px' // Change the font size of the input text
                  // width: '100%'
                  // marginLeft: '2px'
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                borderRadius: '5px',
                color: '#FFF',
                width: '100%',
                fontSize: '13px'
              }}
              onClick={handleButtonClick}
            >
              {isEditMode ? 'Edit Worker' : 'Add Worker'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => onClose(false)}
              variant="contained"
              style={{
                backgroundColor: '#fff',
                borderRadius: '5px',
                color: '#002D8C',
                border: '1px solid #002D8C',
                width: '100%',
                fontSize: '13px'
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        {/* <div style={{ padding: '5% 1% 0% 1%' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#002D8C',
              borderRadius: '5px',
              color: '#FFF',
              width: '48.5%',
              fontSize: '13px'
            }}
            onClick={handleButtonClick}
          >
            {isEditMode ? 'Edit Worker' : 'Add Worker'}
          </Button>
          <Button
            onClick={() => onClose(false)}
            variant="contained"
            style={{
              backgroundColor: '#fff',
              borderRadius: '5px',
              color: '#002D8C',
              border: '1px solid #002D8C',
              margin: '2%',
              width: '48.5%',
              fontSize: '13px'
            }}
          >
            Cancel
          </Button>
        </div> */}
      </Grid>
    </>
  );
};
const AddWorkerModal = ({ isOpen, onClose, selectedRow }) => {
  const isEditMode = !!selectedRow;

  const belowMobile = useMediaQuery('(max-width:767px)');
  

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      ModalContent={<AddWorkerContent isOpen={isOpen} onClose={onClose} selectedRow={selectedRow} isEditMode={isEditMode} />}
      width={belowMobile ? '380px' : '600px'}
      height="auto"
      borderRadius="10px"
      padding="20px"
      showCloseButton={false}
    />
  );
};
export default AddWorkerModal;
