import React, { useEffect, useState } from 'react';
import { Grid, TextField, InputLabel } from '@mui/material';
import { getUserDetails } from 'helper/utils';
export default function ProfileInformationForm() {
  const [userCustomerData, setUserCustomerData] = useState(null);

  useEffect(() => {
    (async () => {
      let res = await getUserDetails();
      setUserCustomerData(res);
    })();
  }, []);
  const profileCustomerData = [
    // { label: 'First name', value: userCustomerData?.firstName, type: 'textfield' },
    { label: 'Full Name', value: userCustomerData?.fullName, type: 'textfield' },
    { label: 'Email Address', value: userCustomerData?.finance_Email__c, type: 'textfield' },
    { label: 'NRIC', value: userCustomerData?.nricNo, type: 'textfield' }
  ];
  return (
    <div>
      {' '}
      <form className="form-bodyy" style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
        <Grid container spacing={2}>
          {profileCustomerData.map((data, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={4} alignItems={'center'}>
                <InputLabel className="inputs_styless_">{data.label}</InputLabel>
                <InputLabel
                  style={{
                    backgroundColor: '#F8F8F8',
                    width: '90%',
                    margin: '2px',
                    padding: '10px',
                    borderRadius: '8px',
                    display: 'flex',
                    color: '#383c4d'
                  }}
                >
                  {data.value}
                </InputLabel>
              </Grid>
              {(index + 1) % 3 === 0 && <div style={{ width: '100%', height: 0 }} />}
            </React.Fragment>
          ))}
        </Grid>
      </form>
    </div>
  );
}
