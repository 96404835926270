import React, { useState, useEffect } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel, Button, Typography, Tooltip, InputAdornment, IconButton } from '@mui/material';
import { usePolicyDetails } from 'modules/packages & premium/hooks/useVehicle';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import { useSelector } from 'react-redux';
import { store } from 'store/index';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import useUenVerification from '../../hooks/useUenVerification';
import InfoIcon from '@mui/icons-material/Info';
function RenderFunction({ item, otherState }) {
  const [value, setValue] = useState(otherState[item?.value]);
  const [dateErrorMessage, setDateErrorMessage] = useState('');

  const onChangeText = (txt, value) => {
    setValue(txt);
    if (item?.texttype === 'date') {
      setDateErrorMessage('');
    }
    store.dispatch(addOtherIndividualState([value, txt])); // Update here
  };

  const onBlur = (value) => {
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];

    if (item?.texttype === 'date') {
      if (allowedCharsForDate.test(value)) {
        if (value >= currentDate) {
          setDateErrorMessage('');
        } else {
          setDateErrorMessage("The start date must be the same as today's date");
        }
        store.dispatch(addOtherIndividualState([item.value, value]));
      } else {
        setDateErrorMessage('Invalid date format. Please enter in YYYY-MM-DD format.');
      }
    } else {
      store.dispatch(addOtherIndividualState([item.value, value]));
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  useEffect(() => {
    try {
      if (item.value === 'POIEndDate' && otherState['POIStartDate']) {
        let newDate = new Date(otherState['POIStartDate']);
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        store.dispatch(addOtherIndividualState(['POIEndDate', formattedDate]));
        setValue(formattedDate);
      }
    } catch (error) {
      console.error('An error occurred in useEffect:', error);
    }
  }, [otherState['POIStartDate'], item]);
  let isFlagm = item.isFlag;
  if (!item.value === 'uen' || !item.value === 'email' || !item.value === 'cm') {
    isFlagm = otherState['isCompanyRegistered'];
  }

  return (
    <>
      {isFlagm && (
        <Grid item xs={12} md={4} alignItems="center">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {item.label === 'Period of Insurance (End Date)' ? (
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-15px' }}>
                <InputLabel className="inputs_styless_" style={{ marginLeft: 0, fontSize: '13px' }}>
                  {item.label}
                </InputLabel>
                <Tooltip
                  title={
                    <span style={{ color: '#00C7B1', fontSize: '13px' }}>
                      For period of insurance with more than 365 days, please contact Anda at 6534 2288.
                    </span>
                  }
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#fff',
                        marginLeft: '8px', // To add space between the icon and the tooltip
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                      }
                    }
                  }}
                >
                  <IconButton>
                    <InfoIcon style={{ color: '#00C7B1', marginTop: '-20px', fontSize: '25px' }} />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <InputLabel className="inputs_styless_" style={{ marginLeft: 0, fontSize: '13px' }}>
                {item.label}
              </InputLabel>
            )}
            {/* <InputLabel className="inputs_styless_" style={{ marginLeft: 0, fontSize: '13px' }}>
              {item.label}
            </InputLabel> */}
            {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
          </div>
          {item.type === 'textField' ? (
            <>
              <TextField
                type={item?.texttype}
                disabled={item?.value == 'UEM' && otherState['navigateFrom'] == 'renewal' ? true : item?.isEnable}
                onChange={(txt) => onChangeText(txt.target.value, item?.value)}
                onBlur={() => onBlur(value)}
                required={item?.isRequired}
                size="small"
                variant="standard"
                className="txtinputs_stylingg"
                value={value}
                style={{ marginTop: '5px', marginLeft: 0, fontSize: '13px' }}
                inputProps={{
                  min: new Date().toISOString().split('T')[0],
                  max: (() => {
                    const maxDate = new Date();
                    maxDate.setFullYear(maxDate.getFullYear() + 10);
                    return maxDate.toISOString().split('T')[0];
                  })(),
                  pattern: '\\d{1,2}/\\d{1,2}/\\d{4}',
                  style: {
                    fontSize: '13px'
                  }
                }}
                InputProps={{
                  disableUnderline: true
                  // endAdornment: item.value === 'POIEndDate' && (
                  //   <InputAdornment position="end">
                  //     <Tooltip
                  //       title={
                  //         <span style={{ color: '#00C7B1', fontSize: '13px' }}>
                  //           For period of insurance with more than 365 days, please contact Anda at 6534 2288.
                  //         </span>
                  //       }
                  //       componentsProps={{
                  //         tooltip: {
                  //           sx: {
                  //             backgroundColor: '#fff'
                  //           }
                  //         }
                  //       }}
                  //     >
                  //       <IconButton>
                  //         <InfoIcon style={{ color: '#00C7B1' }} />
                  //       </IconButton>
                  //     </Tooltip>
                  //   </InputAdornment>
                  // )
                }}
              />
              <InputLabel className="inputs_styless_" style={{ marginLeft: 0, color: 'red' }}>
                {dateErrorMessage}
              </InputLabel>
            </>
          ) : (
            <></>
          )}
          {item.type === 'dropdown' && (
            <DropDown
              width={'96%'}
              heightsss={'37px'}
              height={'40px'}
              marginTop={'6px'}
              options={item?.ddoptions || []}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          )}
        </Grid>
      )}
    </>
  );
}

const MotorVehiclePolicyInformation = React.memo((props) => {
  const { data } = usePolicyDetails();
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { handleVerifyClick } = useUenVerification();
  const [isOpen, setIsOpen] = useState(true);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setShowErrorFields(false);
  }, []);

  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Policy Details">
      {data.map((item, index) => {
        return (
          <RenderFunction
            key={index}
            item={item}
            index={index}
            showErrorFields={showErrorFields}
            verificationMessage={verificationMessage}
            setVerificationMessage={setVerificationMessage}
            otherState={otherState}
            handleVerifyClick={handleVerifyClick}
          />
        );
      })}
    </CardLayout>
  );
});

export default MotorVehiclePolicyInformation;
