import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Anda_Insurance from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_Office.jpg';
import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';

const AboutAndaInsurance = () => {
  const isMobileView = useMediaQuery('(max-width:600px)');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // marginTop: '5%',
        width: '100%'
      }}
    >
      <Typography sx={{ color: '#000', fontSize: '30px', fontFamily: 'Medium', mb: 5, fontWeight: 400, textAlign: 'center' }}>
        About ANDA Insurance
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '95%',
          '@media (min-width: 960px)': {
            flexDirection: 'row',
          width: '75.5%',

          }
        }}
      >
        <Box
          component="img"
          src={Anda_Insurance}
          alt=""
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: '10px',
            marginBottom: '1%',
            '@media (min-width: 960px)': {
              width: '50%',
              marginRight: '3%',
              marginBottom: '0'
            }
          }}
        />
        <Box
          sx={{
            width: '100%',
            '@media (min-width: 960px)': {
              width: '45%'
            }
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '500',
              fontFamily: 'Medium',
              width: '100%',
              mb: 1,
              marginTop: isMobileView ? '18%' : '2%',

              '@media (max-width: 959px)': {
                marginTop: isMobileView ? '18%' : '7%'
              }
            }}
          >
            ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three decades.
            Leveraging on its strong and established relationships with industry partners, ANDA has built its reputation on providing a wide
            array of insurance solutions at competitive prices.
          </Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%', mb: 1, mt: 1 }}>
            We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients' businesses to operate
            optimally. We ensure you have the cover you really need.
          </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Medium', width: '100%' }}>Why ANDA Insurance</Typography>
          <Typography variant="body2" sx={{ fontSize: '0.875rem', fontWeight: '500', fontFamily: 'Medium', width: '100%', mt: 1, mb: 2 }}>
            With more than 30 years of experience in securing our customers' insurance needs, we are committed to providing you with
            unparalleled service!
          </Typography>
          <ul style={{ paddingLeft: '15px', fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
            <li style={{ paddingLeft: '15px', fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
              Competitive Premiums
            </li>
            <li style={{ paddingLeft: '15px', fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
              Get your Policy Documents issued instantly
            </li>
            <li style={{ paddingLeft: '15px', fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
              Assured and Dedicated Service
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutAndaInsurance;
