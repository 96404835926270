import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DomesticHelperPlansTable from 'modules/customer/domestic helper policy view/components/DomesticHelperPlansTable';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useMediaQuery } from '../../../../../node_modules/@mui/material/index';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CoveragesPlanTableDomesticHelper from 'modules/customer/domestic helper policy view/components/CoveragesPlanTableDomesticHelper';

// const pointData = [
//   { point: 'Security bond of $5,000 to Ministry of Manpower' },
//   { point: '24 hour worldwide personal accident cover of up to $100,000 for your domestic helper (except on home leave)' },
//   { point: 'Hospitalisation and surgical expenses of up to $100,000 per year' },
//   { point: 'Outpatient cancer treatment and/or kidney Dialysis of up to S$4,000' },
//   { point: 'Critical illness of up to S$3,000' },
//   { point: 'Special Grant of up to S$4,000' },
//   { point: "Up to S$7,500 for your domestic helper's liability to third parties" },
//   { point: 'In-Hospital Psychiatric Treatment up to S$5,000' },
//   { point: 'Dental Care of up to S$3,000' },
//   { point: 'Fidelity Guarantee up to S$2,000' },
//   { point: 'Re-hiring Expenses of up to S$1,000' },
//   { point: 'Repatriation Expenses in the event of death or permanent disablement' },
//   { point: 'Wages Reimbursement of up to S$35 per day (Max 60 days of Hospitalisation)' }
// ];

const faqsData = [
  {
    heading: 'I need a foreign maid. How do I apply for one?',
    description:
      "Apply online for a Foreign Domestic worker work permit at Ministry of Manpower's (MOM) website . https://www.mom.gov.sg/passes-and-permits/work-permit-for-foreign-domestic-worker"
  },
  {
    heading: 'What to do after approval of my MOM application?',
    description:
      'You need to submit to MOM, a Security Bond of S$5,000 , a medical insurance of at least S$60,000 and a personal accident insurance of S$60,000 per year.'
  },
  {
    heading: 'How and where do I get these documents?',
    description:
      "Please click here to apply online OR Directly download the application form, complete the details, and email to us OR Come by our office conveniently located at: 1 King George's Avenue #06-00 Rehau Building Singapore 208557"
  },
  {
    heading:
      'My helper’s work permit is expiring. Do I need to arrange a new Foreign Domestic Helper Insurance before I can renew her work permit with MOM?',
    description:
      'Yes, you will first need to arrange a new Foreign Domestic Helper Insurance. You can do so on our website at www.anda.com.sg to purchase. We recommend that you do this as early as possible or at least two weeks before the expiry of your helper’s work permit in the event of any unforeseen situations, especially if your helper is going back to her country for home leave.'
  },
  {
    heading: 'How long does ANDA need to process the Foreign Domestic Helper Insurance application and transmit to MOM?',
    description:
      'If you submit the application before 5.30 pm on a weekday, we will process on the same day. The transmission to MOM takes about one working day. Typically, by the next working day, we will be able to confirm if your application is successful. When the transmission to MOM is successful, we will notify the employer. We recommend that you arrange the Foreign Domestic Helper Insurance as early as possible or at least two weeks before the expiry of your helper’s work permit to avoid any unforeseen situations.'
  },
  {
    heading:
      'What is the next step after purchasing my Foreign Domestic Helper Insurance? When can I login to MOM’s system to process the renewal of my helper’s work permit?',
    description:
      'When the transmission to MOM is successful, you will receive an email from us. You can then proceed to login to MOM’s system to process your helper’s work permit online.'
  },
  {
    heading: 'What is the period of insurance required by MOM – 14 or 26 months?',
    description:
      "It depends on your requirements and your contract with your helper. Depending on the period of insurance you apply, MOM will issue the work permit for the period accordingly. Under MOM’s requirements, all insurance and security bond must cover an additional 2 months' period of insurance. This is in the event if the helper overstays in Singapore after her work permit has expired, the insurance is still valid. We do not charge any premium for the additional 2 months' grace period as required by MOM."
  },
  {
    heading: 'What is the Security Bond Protector benefit?',
    description:
      'This is to protect your liability in the event the breach of the S$5,000 security bond is not due to your negligence or fault as an employer.You can purchase this cover by paying an additional premium but this is subject to an excess of S$250 on each claim. '
  },
  {
    heading: 'What should I indicate on my application as the effective date of the insurance?',

    point1: 'For New Helper: Effective date is on or before the arrival date of your domestic helper into Singapore.',
    point2: 'For Transfers: Effective date is the start date you are applying for the issuance of the work permit with MOM.',
    point3: 'For Renewal: Effective date is the day after the expiry date of the present work permit.'
  },
  {
    heading: 'What is SB Transmission Ref No.?',
    description:
      "This is the Security Bond Transmission Refrence Number found on MOM's In-Principle Approval or Renewal Letter. This number is required for the successful transmission to MOM. Without this information, you will not be able to renew your helper's work permit."
  },
  {
    heading: 'What does the Medical Expenses under the Personal Accident and Hospitalisation & Surgical benefits cover?',
    description:
      'For Medical Expenses under the Personal Accident section, insurer will reimburse the Insured Person’s (your helper) outpatient medical expenses only due to an accident. Under the Hospitalisation & Surgical Expenses section, insurer will reimburse the hospitalisation medical expenses as a result of an accident, bodily injury or illness or disease.'
  },
  {
    heading: 'Do I need to purchase the Embassy Bond for my helper?',
    description:
      'You only need to purchase the Embassy Bond if you have a Filipino domestic helper who requires renewing her passport or if she is going back on home leave.'
  },
  {
    heading: 'If I change my mind, can I still upgrade my insurance plan after my Foreign Domestic Helper Insurance is already effective?',
    description:
      'Yes, you can do so within 3 months from the effective date of the policy, subject to a premium loading and a 30-day waiting period.'
  },
  {
    heading: 'Does the Domestic Helper Insurance cover my helper when she goes back for home leave?',
    description:
      'The Domestic Helper Insurance cover ceases from the time your helper leaves Singapore and resumes when she returns from home leave to continue her employment with you without cancellation of her work permit.'
  },
  {
    heading: 'Is there any refund premium when I cancel my Foreign Domestic Helper Insurance?',
    description:
      'Yes, if the policy is cancelled within 9 months from the effective date of the Foreign Domestic Helper Insurance to the date stated on the Discharge Letter from MOM (not the date your helper leaves Singapore).'
  },
  // {
  //   heading: 'Cancellation of the Policy',
  //   point1: 'Before the date of commencement',
  //   point2: 'Within 1 month from the date of commencement',
  //   point3: 'Within 2nd month from the date of commencement',
  //   point4: 'Between 2 and 4 months from the date of commencement',
  //   point5: 'Between 4 and 6 months from the date of commencement',
  //   point6: 'Between 6 and 9 months from the date of commencement',
  //   point7: 'After 9 months from the date of commencement'
  // },
  // {
  //   heading: '% of Premium to be Refunded',
  //   point1:
  //     'Admin charge $50.00 exclusive of GST ($54.00 inclusive of GST for 2023 policies; $54.50 inclusive of GST for 2024 and later policies)',
  //   point2:
  //     '90% (minimum $50.00 exclusive of GST) ($54.00 inclusive of GST for 2023 policies; $54.50 inclusive of GST for 2024 and later policies)',
  //   point3: '80%',
  //   point4: '50%',
  //   point5: '30%',
  //   point6: '15%',
  //   point7: 'No Refund'
  // },
  {
    heading: 'I need to file a Foreign Domestic Helper Insurance claim. How can I submit the claim?',
    description:
      'If you are covered under Etiqa Insurance Pte. Ltd, visit their website https://www.etiqa.com.sg/personal-claims/ and click on the “Maid” tab. Proceed to register for an account to enable the claim submission. If you are covered under AXA Insurance Pte Ltd, you may submit your claim at https://www.axa.com.sg/customer-care/claim-forms/smarthelper.'
  },
  {
    heading: 'How do I obtain a quotation or purchase the Foreign Domestic Helper Insurance?',

    point1: 'You can view details of our Foreign Domestic Helper Insurance here.',
    point2: 'To purchase our Foreign Domestic Helper Insurance, please visit our website here.'
  },
  {
    heading:
      'If my helper’s passport expires in 12 months or less, can I still purchase the Foreign Domestic Helper Insurance for 26 months?',
    description:
      'Yes, you can. MOM will issue a 24-month work permit for your helper. However, do remember to renew your helper’s passport at least 6 months before the expiry or she will become an over stayer in Singapore which will result in penalties.'
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function ProductDetailVerticalTab() {
  const [value, setValue] = React.useState(0);
  const [openStates, setOpenStates] = React.useState(Array(faqsData.length).fill(false));
  const [openSections, setOpenSections] = React.useState({ policy: false, refund: false });

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = (index) => {
    const updatedOpenStates = [...openStates];
    updatedOpenStates[index] = !updatedOpenStates[index];
    setOpenStates(updatedOpenStates);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(min-width:759px) and (max-width:850px)');
  const isLaptop = useMediaQuery('(min-width:851px) and (max-width:950px)');

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          // minWidth: { xs: 0, sm: '250px' },
          marginTop: '3%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: isMobile ? '0%' : '1.5%'
        }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Summary of Coverages & Premium"
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#002d8c ' : '#86878c',
            fontWeight: value === 0 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '16px',
            border: value === 0 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '280px' : isTab ? '100%' : '300px',
            padding: '5px'
          }}
          {...a11yProps(0)}
        />
        {/* <Tab
          label="Product Details"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#002d8c ' : '#86878c',
            fontWeight: value === 1 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '15px',
            border: value === 1 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '200px' : isTab ? '100%' : isLaptop ? '300px' : '280px'
          }}
        /> */}
        <Tab
          label="FAQs"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#002d8c ' : '#86878c',
            fontWeight: value === 1 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '16px',
            border: value === 1 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '100%' : isTab ? '100%' : isLaptop ? '300px' : '300px',
            padding: '5px'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div style={{ width: '100%', marginTop: '2%', marginLeft: isMobile ? '0%' : '2%' }}>
            {/* <DomesticHelperPlansTable width={'100%'} /> */}
            <CoveragesPlanTableDomesticHelper width={'100%'} />
            {/* <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', height: '30px', padding: '2%', color: '#ffffff', marginTop: '5%' }}
            >
              <FileDownloadIcon />
              <Typography style={{ color: '#ffffff', fontFamily: 'Medium', fontSize: '14px' }}>Download Coverages</Typography>
            </Button> */}
          </div>
        </TabPanel>

        {/* <TabPanel value={value} index={1} style={{ marginTop: '1%' }}>
          <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>Domestic Helper</Typography>
          <Typography variant="body1">
            Our eProtect Helper Insurance is a competitive and comprehensive package of benefits developed to meet the needs and obligations
            of individual employers like yourself beyond the requirements of the Ministry of Manpower. it offers you protection against
            unforseen medical or hospitalisation expenses incurred by your domestic helper during her stay with you. Our plans are
            underwritten exclusively by etiqa Insurance Pte.Ltd.
          </Typography>
        </TabPanel> */}

        <TabPanel value={value} index={1}>
          {/* {faqsData.map((item, index) => {
            // Check if current item is 'Cancellation of the Policy' or '% of Premium to be Refunded'
            if (item.heading === 'Cancellation of the Policy' || item.heading === '% of Premium to be Refunded') {
              return null; // Do nothing for these items; we will handle them together below
            }

            // For all other FAQ items
            return (
              <div key={index} style={{ marginBottom: '20px' }}>
                <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>{item?.heading || ''}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {Object.keys(item).map((key, pointIndex) => {
                      if (key !== 'heading') {
                        return (
                          <Typography key={pointIndex} style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>
                            {item[key]}
                          </Typography>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </Grid>
              </div>
            );
          })} */}
          <div>
            {faqsData.map((faq, index) => (
              <div key={index}>
                <div
                  onClick={() => handleToggle(index)}
                  style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', cursor: 'pointer', marginTop: '1%' }}
                >
                  {openStates[index] ? (
                    <ArrowDropDownIcon style={{ marginRight: '8px', color: '#002d8c', marginTop: '1%' }} />
                  ) : (
                    <ArrowRightIcon style={{ marginRight: '8px', color: '#002d8c', marginTop: '1%' }} />
                  )}
                  <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium', marginTop: '1%' }}>
                    {faq?.heading}
                  </Typography>
                </div>

                {/* Show content if the heading is open */}
                {openStates[index] && (
                  <div>
                    <p style={{ marginLeft: '4%', fontSize: '13px' }}>{faq.description}</p>
                    <ul>
                      {faq.point1 && (
                        <li style={{ marginTop: '4%', fontSize: '13px' }}>
                          {faq.point1.split('here')[0]}
                          {/* Replace the below with actual link */}
                          <a href="/DomesticHelper" target="_blank" rel="noopener noreferrer">
                            here
                          </a>
                          {faq.point1.split('here')[1]}
                        </li>
                      )}
                      {faq.point2 && <li style={{ marginTop: '4%', fontSize: '13px' }}>{faq.point2}</li>}

                      {faq.point3 && <li style={{ marginTop: '1%', fontSize: '13px' }}>{faq.point3}</li>}
                      {faq.point4 && <li style={{ marginTop: '1%', fontSize: '13px' }}>{faq.point4}</li>}
                      {faq.point5 && <li style={{ marginTop: '1%', fontSize: '13px' }}>{faq.point5}</li>}
                      {faq.point6 && <li style={{ marginTop: '1%', fontSize: '13px' }}>{faq.point6}</li>}
                      {faq.point7 && <li style={{ marginTop: '1%', fontSize: '13px' }}>{faq.point7}</li>}
                      {/* {faq.point2 &&  <li>{faq.point1}</li>}
                    {faq.point2 &&  <li>{faq.point1}</li>}
                    {faq.point2 &&  <li>{faq.point1}</li>}
                      <li>{faq.point2}</li>
                      <li>{faq.point3}</li>
                      <li>{faq.point4}</li>
                      <li>{faq.point5}</li>
                      <li>{faq.point6}</li>
                      <li>{faq.point7}</li> */}
                      {/* <li>{faq.point1}</li> */}
                    </ul>
                    {/* Render points if available */}
                    {/* {faq.point1 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point1}</p>}
                    {faq.point2 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point2}</p>}
                    {faq.point3 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point3}</p>}
                    {faq.point4 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point4}</p>}
                    {faq.point5 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point5}</p>}
                    {faq.point6 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point6}</p>}
                    {faq.point7 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point7}</p>} */}
                    {/* {faq?.description?.point1 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq?.description?.point1}</p>}
                    {faq?.description?.point2 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq?.description?.point2}</p>} */}
                    {/* {faq.point8 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point8}</p>}
                    {faq.point9 && <p style={{ marginTop: '4%', marinLeft: '4%' }}>{faq.point9}</p>} */}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Handling 'Cancellation of the Policy' and '% of Premium to be Refunded' together */}
          <div style={{ marginBottom: '20px' }}>
            <Grid container spacing={2}>
              {/* Cancellation of the Policy Section */}
              <Grid item xs={6}>
                <div onClick={() => toggleSection('policy')} style={{ display: 'flex', cursor: 'pointer' }}>
                  {openSections.policy ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>Cancellation of the Policy</Typography>
                </div>
                {openSections.policy && (
                  <ul>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>Before the date of commencement</li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Within 1 month from the date of commencement
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Within 2nd month from the date of commencement
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Between 2 and 4 months from the date of commencement
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Between 4 and 6 months from the date of commencement
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Between 6 and 9 months from the date of commencement
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      After 9 months from the date of commencement
                    </li>
                  </ul>
                )}
              </Grid>

              {/* % of Premium to be Refunded Section */}
              <Grid item xs={6}>
                <div onClick={() => toggleSection('refund')} style={{ display: 'flex', cursor: 'pointer' }}>
                  {openSections.refund ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>% of Premium to be Refunded</Typography>
                </div>
                {openSections.refund && (
                  <ul>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      Admin charge $50.00 exclusive of GST ($54.00 inclusive of GST for 2023 policies; $54.50 inclusive of GST for 2024 and
                      later policies)
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>
                      90% (minimum $50.00 exclusive of GST) ($54.00 inclusive of GST for 2023 policies; $54.50 inclusive of GST for 2024 and
                      later policies)
                    </li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>80%</li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>50%</li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>30%</li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>15%</li>
                    <li style={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium' }}>No Refund</li>
                  </ul>
                )}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </Box>
    </Box>
  );
}
