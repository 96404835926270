import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ReusableCard from 'components/cards/ResuableCard';
import DropDown from 'components/dropdown/DropDown';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
import { useVehiclesDetails } from 'modules/packages & premium/hooks/useVehicle';
import AutoCompleteDropdown from 'components/dropdown/AutoCompleteDropdown';
import OverlayLoader from 'components/loader/OverlayLoader';

const ContainerContent = ({ item, index, otherState, setCckwValue }) => {
  const [value, setValue] = useState(otherState[item?.value]);
  const [claimCount, setClaimCount] = useState(otherState?.claimcount);

  useEffect(() => {
    setValue(otherState[item?.value]);
    setClaimCount(otherState?.claimcount);
  }, [otherState[item?.value], otherState?.claimcount]);

  const onChangeText = (txt, value) => {
    try {
      if (value === 'claimamount') {
        txt = txt.replace(/[^0-9.]/g, '');
      }
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error('Error in onChangeText: ' + e);
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      if (selectedItem?.value === 'claimcount') {
        if (selectedItem?.id === 0) {
          onChangeText('', 'claimamount');
        }
        setClaimCount(selectedItem?.id);
      } else if (selectedItem?.value === 'vehicleId') {
        // setSelectedVehicle(selectedItem);
        setCckwValue(selectedItem?.cckw); // Set cckwValue when a vehicle is selected
      }
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error in onValueChange' + e);
    }
  };

  if (item.value === 'cckw' && !otherState.vehicleId) {
    return null;
  }
  return (
    <Grid item xs={12} md={4} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_" style={{ marginLeft: item.type === 'textField' ? '0.5px' : '2px', fontSize: '13px' }}>
          {item.label}
        </InputLabel>
        {item?.astric && item.value !== 'claimamount' && (
          <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-12px' }}>*</div>
        )}
        {item?.astric && item.value === 'claimamount' && (claimCount === 2 || claimCount === 1) && (
          <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-12px' }}>*</div>
        )}
      </div>
      {item.type === 'textField' && (
        <TextField
          type={item?.texttype}
          disabled={item.value === 'cckw' || item.value === 'workShopType' || (claimCount === 0 && item?.value === 'claimamount')}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={value}
          style={{ marginLeft: 0, fontSize: '13px' }}
          inputProps={{
            max: new Date().toISOString().split('T')[0],
            style: {
              fontSize: '13px', // Change the font size of the input text
              marginLeft: '5px'
            }
          }}
        />
      )}
      {item.type === 'dropdown' && (
        <DropDown
          width={'96%'}
          marginTop={'10px'}
          heightsss={'37px'}
          height={'40px'}
          backgroundColor="#F8FAFB"
          options={item?.ddoptions || []}
          onValueChange={onValueChange}
          defaultValue={otherState[item?.value]}
        />
      )}
      {item.type === 'autocomplete' && (
        <AutoCompleteDropdown
          width={'96%'}
          heightsss={'38px'}
          marginTop={'10px'}
          height={38}
          backgroundColor="#F8FAFB"
          options={item?.ddoptions || []}
          onChange={onValueChange}
          value={otherState[item?.value]}
        />
      )}
    </Grid>
  );
};

const MotorVehicleDetails = ({ handleNextButton, handlePreviousButton }) => {
  const { data, isLoading } = useVehiclesDetails();
  const otherState = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const { activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  //console.log('activeParams',activeParams);
  const setCckwValue = (cckw) => {
    store.dispatch(addOtherIndividualState(['cckw', cckw]));
  };
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }

  return (
    <ReusableCard
      backgroundColor={'#fff'}
      border="1px solid #ddd"
      padding={'3%'}
      borderRadius={15}
      content={
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} mb={1} className="inputContainer">
            <div className="inputLabel" fontSize="small">
              <InputLabel style={{ fontSize: '13px', marginBottom: '10px', fontFamily: 'Medium', color: '#636468' }}>
                Product name
              </InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="commonInput"
              value={activeParams?.productName}
              disabled
              // onChange={(e) => setFirstName(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                style: {
                  fontSize: '13px', // Change the font size of the input text
                  marginLeft: '2px'
                }
              }}
              style={{ width: '98.8%' }}
            />
          </Grid>

          {data.map((item, index) => (
            <ContainerContent
              key={index}
              item={item}
              otherState={otherState}
              handleNextButton={handleNextButton}
              handlePreviousButton={handlePreviousButton}
              setCckwValue={setCckwValue}
            />
          ))}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: '#f7f7f7',
                  width: '100%',
                  padding: '5%',
                  color: '#000000',
                  height: '100%',
                  border: '1px solid #ddd',
                  marginLeft: '5px'
                }}
                onClick={handlePreviousButton}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#002D8C', width: '100%', padding: '5%', color: '#ffffff', height: '100%' }}
                onClick={handleNextButton}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default MotorVehicleDetails;
