import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import anda_logo from '../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import { Typography } from '@mui/material';
import { useMediaQuery } from '../../../../../node_modules/@mui/material/index';

const headerData = [{ title: 'With Co-Payment' }, { title: 'Without Co-Payment' }];

export default function DomesticHelperPlansTable(props) {
  // const isMobile = useMediaQuery('(max-width:600px)');
  // const isTab = useMediaQuery('(min-width:601px) and (max-width:900px)');
  // const isTab = useMediaQuery('(min-width:601px) and (max-width:1380px) ');
  const { width, bottomButtons, rows } = props;

  const isMobileBtnTab = useMediaQuery('(max-width:400px)');
  const isMobile = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isTab = useMediaQuery('(min-width:601px) and (max-width:900px)');
  const isDesktop = useMediaQuery('(min-width:1025px) and (max-width:1440px)');

  return (
    <TableContainer
      component={Paper}
      style={{
        width: isMobileBtnTab ? '280px' : isMobile ? '90%' : isTab ? '550px' : props.width,
        margin: 'auto',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        overflowX: isMobileBtnTab || isMobile ? 'scroll' : 'hidden', // Add scroll for mobile
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
        // backgroundColor:'red'

        // display: 'flex',
        // justifyContent: 'center'
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#012D8C' }}>
            <TableCell
              align="left"
              style={{
                padding: '1%',
                alignItems: 'center',
                width: isMobile ? '100%' : isMobileBtnTab ? '200px' : isDesktop ? '700px' : '420px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Logo */}
                <img
                  src={anda_logo}
                  alt="ANDA logo"
                  style={{
                    width: '80px',
                    filter: 'brightness(0) invert(1)',
                    mixBlendMode: 'screen',
                    marginRight: '10px'
                  }}
                />
                {/* Text next to the logo */}
                {/* {!isMobile && (
                  <div style={{ color: '#fff' }}>
                    <Typography variant="h6" style={{ marginBottom: 2 }}>
                      Motor Insurance
                    </Typography>
                    <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                      underwritten by Etiqa
                    </Typography>
                  </div>
                )} */}
              </div>
            </TableCell>
            {headerData.map((header, index) => (
              <TableCell
                key={index}
                align="left"
                style={{
                  color: '#FFFFFF',
                  width: '100px' // Fixed width for Basic, Silver, and Platinum
                  // padding: '4px 8px' // Reduce padding for more compact spacing
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                  <Typography style={{ fontSize: '16px' }}>{header.title}</Typography>
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
              }}
            >
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{
                  padding: '1%',
                  width: isMobile ? '180px' : 'auto', // Wider for name column,
                  fontSize: '13px'
                }}
              >
                {row.name}
              </TableCell>
              <TableCell style={{ width: '200px', padding: '4px 8px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}>{row.basic}</Typography>
              </TableCell>
              <TableCell style={{ width: '200px', padding: '4px 8px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}> {row.silver}</Typography>
              </TableCell>
            </TableRow>
          ))}
          {props.bottomButtons ? (
            <TableRow
              style={{
                backgroundColor: '#012D8C',
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
                padding: '2%',
                fontSize: '13px'
              }}
            >
              <TableCell></TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px', fontSize: '13px' }}>
                  Buy Now
                </button>
              </TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DomesticHelperPlansTable.defaultProps = {
  width: '100%',
  bottomButtons: false,
  rows: [
    { name: 'Co-Insurance (Applicable to Hospitalisation)', basic: '25% Co-Payment above $15,000', silver: 'No Co-Payment' },
    {
      name: 'Hospitalisation & Surgical In-Patient & Day Surgery Expenses',
      basic: 'As Charged up to $60,000 per Disability',
      silver: 'As Charged up to $60,000 per Disability'
    },
    { name: 'Repatriation Expenses', basic: 'Unlimited', silver: 'Unlimited' },
    { name: 'Accidental Death', basic: '$10,000', silver: '$30,000' },
    { name: 'Permanent Disablement', basic: '$10,000', silver: '$30,000' },
    { name: 'Medical Expenses', basic: 'N.A.', silver: '$2,000' },
    { name: 'Wages Reimbursement', basic: 'N.A.', silver: 'Up to $35 Per Day (Maximum 60 Days)' }
  ]
};
