import React from 'react';
import Footer from './Footer';
// import { Grid } from '../../../../../../node_modules/@mui/material/index';
import NavBar from './NavBar';

const TermsOfUse = () => {
  return (
    <div
      className="term-container"
      style={{
        // padding: '12px 40px',
        fontFamily: 'Medium',
        fontSize: '15px',
        letterSpacing: '1.3px',
        color: '#1B222F',
        backgroundColor: '#fff'
      }}
    >
      <NavBar />
      <div style={{ maxWidth: '75%', textAlign: 'left', margin: '0 auto', marginTop: '3%' }}>
        {/* <img src="apple-touch-icon.png" alt="ANDA" width="129" height="100" /> */}
        <p style={{ fontSize: '13px' }}>PLEASE READ THESE TERMS AND CONDITIONS BEFORE USING THIS WEBSITE</p>
        <h1 style={{ fontFamily: 'Medium', fontSize: '27px', letterSpacing: '2px', color: '#00968F' }}>Website Terms and Conditions</h1>
        <p style={{ fontSize: '13px' }}>
          This website is operated by Anda Insurance Agencies Pte Ltd ("Anda"). Please read these terms and conditions ("Terms") carefully.
          By accessing this website you agree to be bound by these terms, including the Anda privacy policy. If you do not agree to be bound
          by these terms you should not access or view this website.
        </p>
        <h1 style={{ fontFamily: 'Medium', fontSize: '27px', letterSpacing: '2px', color: '#00968F' }}>Terms of use</h1>
        <ol>
          <li>
            <p style={{ fontSize: '13px' }}>
              While Anda has made all reasonable efforts to ensure that the information on this website was correct at the time of
              inclusion, you should be aware that Anda accepts no responsibility if the information is incomplete, inaccurate or out of
              date.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              Except as expressly provided for on this website, Anda is not responsible for the content or use of any web pages or any
              message sent or received by you.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              All present and future copyright, trademarks, and other intellectual property rights relating to this website are vested in
              Anda and its licensors. You acquire no rights in the website other than the limited right to use the website in accordance
              with these terms. You agree to protect the proprietary rights of Anda and its licensors and agree to promptly notify Anda in
              writing (contact details below) upon becoming aware of any unauthorised access or any use of the website that infringes upon
              any proprietary rights.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              You are permitted to print out or download information and content from this website only for your own personal non-commercial
              use or for the non-commercial use of your organisation. You may not offer for sale or sell or distribute over any medium any
              part of this website or its content. You may not make any part of the website available as part of another website, whether by
              hyperlink framing on the Internet or otherwise unless you have been authorised to do so in writing by Anda. To apply for
              authorisation, you must either e-mail or write to Anda (contact details below) with the following details: (a) your name or
              the name of the technical contact responsible for the link(s); (b) the name of your company; (c) the email address and
              telephone number for you or the technical contact responsible for the link(s); and (d) the website address(es) where the
              link(s) to the Home Page will appear. You may not use the Anda name or misuse the Anda brand in any way, such use or misuse to
              be determined solely at the discretion of Anda.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              You shall not use this website for any illegal purposes and in particular you agree that you shall not send, use, copy, post
              or allow any posting which is defamatory or obscene or which is abusive, indecent or in breach of the privacy of any person.
              You agree not to send any unsolicited promotional or advertising material, spam or similar materials or any volume messages
              which may interfere with the operation of this website or with the enjoyment of this website by other visitors.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              Anda reserves the right at any time and without notice to enhance, modify, alter, suspend or permanently discontinue all or
              any part of this website and to restrict or prohibit access to it.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              Information that we acquire will be treated as confidential and will not be disclosed, other than in accordance with our
              Privacy Policy or your instructions. It may also be used to provide our clients with information about products and services
              that we believe could be of interest. When our clients supply us with information containing personal data (names, addresses,
              or other information relating to living individuals), we will hold and use that data to perform general insurance and other
              services for those clients on the understanding that the individuals to whom the data relates have been informed of the
              reason(s) for obtaining the data.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              You hereby agree to indemnify Anda against any costs, claims, losses and damages (including legal fees) incurred by or awarded
              against Anda as a result of your misuse of this website or your breach of these terms.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              While this website may provide links to other websites Anda has no control over such sites. Anda cannot accept any liability
              for the accuracy or otherwise of any content, or the security of any activity carried out on other such sites. Your use of
              such sites is at your sole risk.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              If you are in breach of these terms, Anda may at its option, suspend your access to this website and refuse to provide you
              with any further access to it.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              This website is provided to you free of charge without any warranties or guarantees unless specifically stated and Anda
              accepts no liability to you (except in the case of personal injury or death caused by its negligence or for fraud) whether in
              contract, tort (including negligence) or otherwise, arising out of it in connection with this website. Your sole remedy is to
              discontinue using this website. Anda's liability shall not be limited in the case of death or personal injury directly caused
              by Anda's negligence.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              These terms may be amended by Anda from time to time. Your use of this Website following such changes constitutes your
              acceptance of these changes.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              These terms and conditions and the content of this website are governed by Singapore law whose Courts shall have exclusive
              jurisdiction.
            </p>
          </li>
          <li>
            <p style={{ fontSize: '13px' }}>
              Any questions or enquiries which you have in relation to this website or in relation to Anda should be sent via writing to us
              at:
            </p>
            <p style={{ fontSize: '13px' }}>
              Anda Insurance Agencies Pte Ltd <br />
              1 King George’s Avenue,
              <br />
              #06-00 Rehau Building,
              <br />
              Singapore 208557, Singapore
            </p>
          </li>
        </ol>
      </div>
      <div style={{marginTop:'120px'}}>
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfUse;
