import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import anda_logo from '../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import { Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const headerData = [{ title: 'Basic' }, { title: 'Silver' }, { title: 'Gold' }, { title: 'Platinum' }];

export default function CoveragesPlanTableDomesticHelper(props) {
  const isMobile = useMediaQuery('(max-width:400px)');
  const isMobileBtnTab = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isTab = useMediaQuery('(min-width:601px) and (max-width:900px)');
  const { width, bottomButtons, rows } = props;

  return (
    <TableContainer
      component={Paper}
      style={{
        width: isMobile ? '280px' : isMobileBtnTab ? '90%' : props.width, // Set width for mobile
        margin: 'auto',
        borderRadius: '15px',
        overflowX: isMobile || isMobileBtnTab ? 'scroll' : 'hidden', // Add scroll for mobile
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px'
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#012D8C' }}>
            <TableCell
              align="left"
              style={{
                padding: '1%',
                alignItems: 'center',
                width: isMobile ? '180px' : isMobileBtnTab ? '100%' : '420px' // Set width for mobile
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: isTab ? 'column' : 'row' }}>
                {/* Logo */}
                <img
                  src={anda_logo}
                  alt="ANDA logo"
                  style={{
                    width: '80px',
                    filter: 'brightness(0) invert(1)',
                    mixBlendMode: 'screen',
                    marginRight: '10px'
                  }}
                />
              </div>
            </TableCell>
            {headerData.map((header, index) => (
              <TableCell
                key={index}
                align="left"
                style={{
                  color: '#FFFFFF',
                  width: '100px' // Fixed width for Basic, Silver, and Platinum
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                  <Typography style={{ fontSize: '16px' }}>{header.title}</Typography>
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
              }}
            >
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{
                  padding: '1%',
                  width: isMobile ? '180px' : 'auto', // Wider for name column, adjust for mobile
                  // fontWeight: 'bold'
                  fontSize: '13px'
                }}
              >
                {row.name}
              </TableCell>
              <TableCell style={{ width: '100px', padding: '4px 8px', fontSize: '13px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}>{row.basic}</Typography>
              </TableCell>
              <TableCell style={{ width: '100px', padding: '4px 8px', fontSize: '13px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}>{row.silver}</Typography>
              </TableCell>
              <TableCell style={{ width: '100px', padding: '4px 8px', fontSize: '13px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}>{row.Gold}</Typography>
              </TableCell>
              <TableCell style={{ width: '100px', padding: '4px 8px', fontSize: '13px' }} align="left">
                <Typography style={{ marginLeft: '5px', fontSize: '13px' }}>{row.platinum}</Typography>
              </TableCell>
            </TableRow>
          ))}
          {props.bottomButtons ? (
            <TableRow
              style={{
                backgroundColor: '#012D8C',
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
                padding: '2%'
              }}
            >
              <TableCell></TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CoveragesPlanTableDomesticHelper.defaultProps = {
  width: '70%',
  bottomButtons: false,
  rows: [
    { name: 'Letter of Guarantee to Ministry of Manpower', basic: '$5,000', silver: '$5,000', Gold: '$5,000', platinum: '$5,000' },
    { name: 'Accidental Death/Permanent Disablement', basic: '$60,000', silver: '$70,000', Gold: '$80,000', platinum: '$100,000' },
    { name: 'Medical Expenses', basic: '$1,000', silver: '$3,000', Gold: '$4,000', platinum: '$4,000' },
    { name: 'TCM Expenses (Per Accident)', basic: 'N.A.', silver: '$200', Gold: '$300', platinum: '$300' },
    { name: 'Repatriation Expenses', basic: '$10,000', silver: 'Unlimited', Gold: 'Unlimited', platinum: 'Unlimited' },
    {
      name: 'Hospitalisation & Surgical Expenses (Per Year) Co-Payemnt of 25% for the amount above the first $15,000 applies to Basic Plan Only',
      basic: '$60,000',
      silver: '$60,000',
      Gold: '$80,000',
      platinum: '$100,000'
    },
    { name: 'Outpatient Cancer Treatment and/or Kidney Dialysis', basic: 'N.A.', silver: '$3,000', Gold: '$3,000', platinum: '$3,000' },
    { name: 'Critical Illness', basic: 'N.A.', silver: '$3,000', Gold: '$3,000', platinum: '$3,000' },
    { name: 'Wages Reimbursement (per day)', basic: '$35 ', silver: '$35 ', Gold: '$35 ', platinum: '$35 ' },
    { name: 'Re-hiring Expenses', basic: '$750', silver: '$750', Gold: '$1,000', platinum: '$1,000' },
    { name: "Domestic Worker's Liability To Third Party", basic: '$5,000', silver: '$5,000', Gold: '$7,500', platinum: '$7,500' },
    { name: 'Special Grant', basic: '$3,000', silver: '$3,000', Gold: '$4,000', platinum: '$4,000' },
    { name: 'Plan (26 Months)', basic: '$490.50', silver: '$591.33', Gold: '$694.88', platinum: '$794.07' },
    { name: 'Premium (14 Months)', basic: '$367.88', silver: '$443.50', Gold: '$521.16', platinum: '$595.55' }
  ]
};
