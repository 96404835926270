import React from 'react';
import { Grid } from '@mui/material';
import sliderpicture1 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_Office.jpg';
import NavBar from './NavBar';
import Footer from './Footer';
import { Typography } from '../../../../../../node_modules/@mui/material/index';
import img3 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Decades of-Trusted Expertise-14.svg';
import img2 from '../../../../../assets/images/AndaNewImagesFolder/02.09.2024/Home icons/ANDA_Decades of-Trusted Expertise-17.svg';
import img1 from '../../../../../assets/images/ANDA-Icons/08.png';
import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';

const data = [
  {
    id: 1,
    img: img1,
    text: 'Competitive Premiums'
  },
  {
    id: 2,
    img: img2,
    text: 'Get your Policy Documents issued instantly'
  },
  {
    id: 3,
    img: img3,
    text: 'Assured and Dedicated Service'
  }
];

const AboutAndaTemplate = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Grid
      direction="column"
      style={{ minHeight: '100vh', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column' }}
      wrap="nowrap" // Prevents the Grid from wrapping
    >
      {/* NavBar at the top */}
      <Grid item xs={12}>
        <NavBar />
      </Grid>

      {/* Content Section */}
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{ width: '100%', position: 'relative', height: '35vh' }}>
          {/* Image with text on bottom 10% */}
          <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '35vh',objectFit:'cover' }} />
          <div
            style={{
              position: 'absolute',
              top: '20%',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
              color: 'white',
              zIndex: 1,
              width: '100%' // Constrain the width of the text
            }}
          >
            <Typography style={{ fontSize: '30px', fontFamily: 'Medium', color: '#ffffff' }}>About ANDA</Typography>
            <Typography style={{ fontSize: '13px', fontFamily: 'Medium', color: '#ffffff' }}>Tailored, Reliable,Always Ready</Typography>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
              <div
                style={{
                  width: '13%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: '1.5px solid #ffffff'
                }}
              ></div>
              <div
                style={{
                  width: '13%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: '1.5px solid #ffffff',
                  height: '3px'
                }}
              ></div>
            </div> */}
          </div>
        </div>

        {/* Text Content Section Positioned Over Image */}
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          style={{
            // backgroundColor: 'red',
            borderRadius: '12px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '70%', marginBottom: '10px', margin: '3% 0% 3% 0%' }}>
            <Typography
              style={{
                fontSize: '13px',
                fontFamily: 'Medium',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              {
                'ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three decades. Leveraging on its strong and established relationships with industry partners, ANDA has built its reputation on providing a wide array of insurance solutions at competitive prices.'
              }
            </Typography>
            <Typography
              style={{
                fontSize: '13px',
                fontFamily: 'Medium',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                margin: '2% 0% 0% 0%'
              }}
            >
              {
                'We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients’ businesses to operate optimally. We ensure you have the cover you really need.'
              }
            </Typography>

            {/* <Typography
              style={{
                fontSize: '13px',
                fontFamily: 'Medium',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              {
                'With more than 30 years of experience’ in securing our customers’ insurance needs, we are committed to providing you with unparalleled service! '
              }
            </Typography>
            <ul>
              <li style={{ fontSize: '13px', fontFamily: 'Medium', marginTop: '10px' }}>Competitive Premiums</li>
              <li style={{ fontSize: '13px', fontFamily: 'Medium', marginTop: '10px' }}>Get your Policy Documents issued instantly</li>
              <li style={{ fontSize: '13px', fontFamily: 'Medium', marginTop: '10px' }}>Assured and Dedicated Service</li>
            </ul> */}
          </div>
        </Grid>
      </Grid>

      {/* Footer at the bottom */}
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        lg={12}
        mb={3}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%' }}
      >
        <div style={{ width: '70%', marginBottom: '10px' }}>
          <Typography
            style={{
              fontSize: '20px',
              fontFamily: 'Medium',
              textDecorationThickness: '2px',
              textAlign: 'center',
              margin: '4% 0% 2% 0%'
            }}
          >
            {'Why ANDA Insurance '}
          </Typography>
          <Typography
            style={{
              fontSize: '13px',
              fontFamily: 'Medium',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2% 0% 2% 0%'
            }}
          >
            {
              'With more than 30 years of experience’ in securing our customers’ insurance needs, we are committed to providing you with unparalleled service! '
            }
          </Typography>
        </div>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          margin: '0% 0% 5% 0%'
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            width: '70%'
          }}
        >
          {data.map((item, index) => (
            <Grid item lg={4} md={6} xs={12} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img
                src={item.img}
                alt="About_ANDA_Points"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '70px'
                  // marginTop: '14px'
                }}
              />
              <Typography
                style={{
                  textAlign: 'center',
                  width: isMobile ? '100%' : '50%',
                  fontFamily: 'Medium',
                  fontWeight: '600',
                  marginTop: '2%'
                }}
              >
                {item.heading}
              </Typography>
              <div style={{ textAlign: 'center', width: isMobile ? '100%' : '60%', fontFamily: 'Medium' }}>{item.text}</div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 'auto' }}>
        {' '}
        {/* marginTop: 'auto' pushes the footer to the bottom */}
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AboutAndaTemplate;
