import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DomesticHelperPlansTable from 'modules/customer/domestic helper policy view/components/DomesticHelperPlansTable';

const faqsData = [
  {
    heading: 'What should I do immediately after a motor accident?',
    description: 'The Motor Claims Framework (MCF) outlines clear steps to follow:',
    point1: '1. Exchange particulars of parties involved and note vehicle numbers',
    point2: '2. Take photos of the accident scene and vehicle damages.',
    point3: "3. Call your insurer's hotline for a tow-truck or further device"
  },
  {
    heading: 'When Should I involve authorities?',
    description: 'Call the police immediately if there are injuries,fatalities, or specific circumstances like hit-and-run'
  },
  {
    heading: "What happens if I don't report the accident to my insurer?",
    description: 'Consequences include liabilities issues,loss or No-Claims Discount, or policy cancellation.'
  },
  {
    heading: 'What actions should I would after an accident?',
    description: "Don't move vehicles before photos,attempt to move injured persons, or engage way unauthorized tow-truck operators."
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function ProductDetailVerticalTab() {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobileView = useMediaQuery('(max-width:600px)');
  const isTabView = useMediaQuery('(min-width:759px) and (max-width:850px)');
  const isLaptop = useMediaQuery('(min-width:851px) and (max-width:950px)');

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ marginTop: '3%', marginLeft: isMobileView ? '0%' : '1.7%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Summary of Coverages"
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobileView ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#05318e' : '#86878c',
            fontWeight: value === 0 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '16px',
            border: value === 0 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobileView ? '280px' : isTabView ? '100%' : '300px',
            padding: '5px'
          }}
          {...a11yProps(0)}
        />
        {/* <Tab
          label="Product Details"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobileView ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#05318e' : '#86878c',
            fontWeight: value === 1 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '15px',
            border: value === 1 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobileView ? '300px' : isTabView ? '250px' : '270px'
          }}
        /> */}
        <Tab
          label="FAQs"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobileView ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            color: value === 1 ? '#002d8c' : '#86878c',
            fontWeight: value === 1 ? '600' : '500',
            fontFamily: 'Medium',
            fontSize: '16px',
            marginTop: '5%',
            border: value === 1 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobileView ? '100%' : isTabView ? '100%' : isLaptop ? '300px' : '300px',
            padding: '5px'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div
            style={{
              width: '100%',
              marginTop: '2%',
              marginLeft: isMobileView ? '0%' : '1.7%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <DomesticHelperPlansTable width={'100%'} />
            {/* <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', height: '30px', padding: '2%', color: '#ffffff', marginTop: '5%' }}
            >
              <FileDownloadIcon />
              <Typography style={{ color: '#ffffff', fontFamily: 'Medium', fontSize: '14px' }}>Download Coverages</Typography>
            </Button> */}
          </div>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>
          <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>Foreign Workers</Typography>
          <Typography variant="body1">
            Maintaining business continuity is a top priority for any company. The last thing you want is to face the challenges of an
            employee being denied entry into Singapore or a workplace accident, resulting in hefty medical expenses. To safeguard your
            business and focus on revenue-generating activities, it's crucial to have the necessary insurance coverage in place. With the
            right insurance, you can have peace of mind even when accidents occur, knowing that the associated costs are taken care of.
            Figuring out what insurance products and selecting suitable coverage for your foreign workers (or migrant workers) can be
            perplexing. It’s tough enough navigating through complex laws and levies, and when you add insurance terminology into the mix,
            it can become even more bewildering. But fear not – we've got you covered.
          </Typography>
        </TabPanel> */}

        <TabPanel style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} value={value} index={1}>
          {/* {faqsData.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>{item.heading}</Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.description}</Typography>
              <ol style={{ paddingLeft: '20px', marginTop: '5px', marginBottom: '5px', listStyleType: 'decimal' }}>
                {index === 0 && (
                  <>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point1}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point2}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point3}</li>
                  </>
                )}
              </ol>
            </div>
          ))} */}
          <Typography style={{ fontSize: '16px', fontWeight: '600' }}>No FAQs are Available.</Typography>
        </TabPanel>
      </Box>
    </Box>
  );
}
