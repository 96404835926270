import React from 'react';
import ResponsiveAppBar from '../../customer/corporate/customerlandingpage/components/NavBar';
import { Grid } from '../../../../node_modules/@mui/material/index';
import Footer from 'modules/customer/corporate/customerlandingpage/components/Footer';
import LoginCustomer from 'modules/customer/customer login/pages/LoginCustomer';
import Login_img from '../../../assets/images/login_img.png';
import useCustomerPolicesGet from 'modules/customer/customer login/hooks/useCustomerPolicesGet';
import OverlayLoader from 'components/loader/OverlayLoader';

const CustomerLogin = () => {
  const [data, loading, isOpen, setIsOpen, selectedPolicy] = useCustomerPolicesGet();

  return (
    <div>
      <ResponsiveAppBar />

      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', width: '100%', overflow: 'hidden', height: '35vh' }}>
        <img src={Login_img} alt="Background" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            justifyContent: 'flex-start',
            left: 350,
            top: 60,
            width: '100%'
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', color: 'white', marginRight: '5%' }}>Our Products</p>
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 2, marginTop: '-60px', width: '100%' }}>
        <Grid item xs={12} md={12} lg={12} mb={10}>
          {/* <LoginCustomer /> */}
          data===[] ?( <OverlayLoader show={loading} />
          ):(
          <LoginCustomer />){/* <LoginCustomer /> */}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default CustomerLogin;
