import React, { useEffect, useState } from 'react';
import { Grid, Button, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ReusableCard from 'components/cards/ResuableCard';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import { AddaddOnsData, addOtherIndividualState, removeAddOnData } from 'store/reducers/premiumPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { DomesticCustomerAddons } from 'modules/packages & premium/static';
import DropDown from 'components/dropdown/DropDown';
import { store } from 'store/index';
import { useGetFWDHCalculationQuery } from 'store/services/DomesticHelperCustomerRTK';
import useNetworkStatus from 'hooks/useNetworkStatus';

const RenderFunction = ({ item }) => {
  const { otherState, activeParams } = useSelector((state) => state?.persistedReducer?.premiumPolicy);

  const [value, setValue] = useState(otherState[item?.value] || '');
  const [error, setError] = useState('');
  const [startDateMessage, setStartDateMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (otherState?.fromDate && item?.value === 'todate') {
      let fromDate = otherState?.fromDate;
      const currentDate = new Date().toISOString().split('T')[0];
      const numOfMonths = parseInt(activeParams?.duration);

      // Check if fromDate is in the past
      if (fromDate < currentDate) {
        fromDate = currentDate; // Set fromDate to the current date
        store.dispatch(addOtherIndividualState(['fromDate', currentDate]));
      }

      if (fromDate && numOfMonths) {
        const fromDateObj = new Date(fromDate);
        fromDateObj.setMonth(fromDateObj.getMonth() + numOfMonths);
        fromDateObj.setDate(fromDateObj.getDate() - 1);
        const toDate = fromDateObj.toISOString().slice(0, 10);
        store.dispatch(addOtherIndividualState(['todate', toDate]));
        setValue(toDate);
      }
    }
  }, [otherState, item?.value, activeParams?.duration]);

  const onValueChange = (selectedItem) => {
    try {
      dispatch(addOtherIndividualState([item.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error(e);
    }
  };

  // const onChangeText = (txt, value) => {
  //   const allowedChars = /^[a-zA-Z0-9 ]*$/;
  //   const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
  //   const currentDate = new Date().toISOString().split('T')[0];
  //   try {
  //     if (item?.texttype === 'date') {
  //       if (allowedCharsForDate.test(txt)) {
  //         if (txt >= currentDate) {
  //           setValue(txt);
  //           store.dispatch(addOtherIndividualState([value, txt]));
  //         } else {
  //           setValue(currentDate);
  //           store.dispatch(addOtherIndividualState([value, currentDate]));
  //         }
  //       } else {
  //       }
  //     } else {
  //       if (allowedChars.test(txt)) {
  //         setValue(txt);
  //         store.dispatch(addOtherIndividualState([value, txt]));
  //       } else {
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  const validateDate = (date) => {
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];

    if (allowedCharsForDate.test(date)) {
      if (date >= currentDate) {
        setError('');
        if (date === currentDate) {
          setStartDateMessage('Start date is today.');
        } else {
          setStartDateMessage('');
        }
        return true;
      } else {
        setError('Date cannot be earlier than today.');
        setStartDateMessage('');
        return false;
      }
    } else {
      setError('Invalid date format. Please enter in YYYY-MM-DD format.');
      setStartDateMessage('');
      return false;
    }
  };

  const onChangeText = (event) => {
    const { value } = event.target;
    if (item?.texttype === 'date') {
      const year = value.split('-')[0];
      if (year.length <= 4) {
        setValue(value);
        validateDate(value); // Validate immediately on change
      }
    } else {
      setValue(value);
    }
  };
  const onBlur = () => {
    if (item?.texttype === 'date') {
      if (validateDate(value)) {
        // If the date is valid, dispatch it to Redux
        store.dispatch(addOtherIndividualState([item.value, value]));
      } else {
        // If the date is invalid (past date), set the value to the current date
        const currentDate = new Date().toISOString().split('T')[0];
        setValue(currentDate);
        store.dispatch(addOtherIndividualState([item.value, currentDate]));
      }
    } else {
      store.dispatch(addOtherIndividualState([item.value, value]));
    }
  };
  return (
    <>
      <Grid item xs={12} md={4}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography style={{ fontFamily: 'Medium', color: '#636468', fontSize: '13px', marginLeft: '8px' }}>{item.label}</Typography>
          {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '3px', marginTop: '-10px' }}>*</div>}
        </div>
        {item?.type == 'textField' && (
          <div style={{ marginTop: '5px' }}>
            <TextField
              type={item?.texttype}
              disabled={item?.isEnable}
              onChange={onChangeText}
              onBlur={onBlur}
              // onInput={handleInput}
              required={item?.isRequired}
              placeholder={item.value === 'fin' ? 'e.g. S3272118D' : ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              // className="txtinputs_stylingg"
              // sx={{ pb: 0 }}
              style={{
                display: 'flex',
                backgroundColor: '#F8FAFB',
                borderRadius: '8px',
                width: '100%',
                padding: '9px 5px 5px 5px',
                marginLeft: '8px',
                // marginTop: '10px',
                fontSize: '13px ',
                justifyContent: 'center'
                // alignItems: 'center'
              }}
              value={value}
              inputProps={{
                pattern: '\\d{4}',
                min: '1900',
                min: new Date().toISOString().split('T')[0],
                style: {
                  fontSize: '13px' // Change the font size of the input text
                }
              }}
            />
            {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
            {startDateMessage && <div style={{ color: 'red', marginTop: '5px' }}>{startDateMessage}</div>}
          </div>
        )}
        {item?.type == 'dropdown' && (
          <div style={{ marginTop: '15px' }}>
            <DropDown
              width={'96%'}
              heightsss={'38px'}
              backgroundColor="#F8FAFB"
              options={item.ddoptions}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          </div>
        )}
      </Grid>
    </>
  );
};

const DomesticHelperPolicyAddOn = ({ handleNextButton, handlePreviousButton }) => {
  const { activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { data, error: getError } = useGetFWDHCalculationQuery(activeParams?.productID);
  const [getdata, setData] = useState([]);
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { addOn } = otherState;
  const dispatch = useDispatch();
  const network = useNetworkStatus();

  const handleCheckboxChange = (item, checked) => {
    let obj = {
      itemCode: item?.productID,
      salesPrice: item?.addOnAmount,
      quantity: 1
    };
    if (checked) {
      dispatch(AddaddOnsData([item?.fwdhProductPricingListId, obj]));
    } else {
      dispatch(removeAddOnData(item?.fwdhProductPricingListId));
    }
  };

  const DomesticHelperAddonRows = data?.data?.foreignWorkerCalculation?.map((item, index) => {
    return {
      sr: index + 1,
      productid: item?.productName,
      Prices: `$ ${item.addOnAmount || '-'}`,
      price: (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={addOn[item?.fwdhProductPricingListId] ? true : false}
                onChange={(e) => handleCheckboxChange(item, e.target.checked)}
              />
            }
          />
        </div>
      )
    };
  });

  useEffect(() => {
    setData(DomesticCustomerAddons);
  }, []);

  return (
    <>
      {/* <OverlayLoader show={loading} /> */}
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'5%'}
        boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <>
            <Grid item xs={12}>
              <p style={{ fontSize: '16px', fontWeight: '500', marginTop: '2rem', marginLeft: '1%' , fontFamily: 'Medium'}}>{'A. Add-On Cover'}</p>
              <hr />
            </Grid>
            <Grid container spacing={2} mb={4} mt={5}>
              {getdata.map((item, index) => (
                <RenderFunction key={index} item={item} index={index} />
              ))}
            </Grid>

            {/* AddOns */}
            <p style={{ fontSize: '16px', fontWeight: '500', marginTop: '2rem', marginBottom: '2rem', marginLeft: '1%', fontFamily: 'Medium' }}>
              {'B. Add-On Details'}
            </p>
            <hr />
            {network ? (
              <Grid item xs={12}>
                <RiderSectionTable columns={DomesticHelperCustomerPolicyAddons} rows={DomesticHelperAddonRows} />
              </Grid>
            ) : (
              <Grid xs={12} mt={5} mb={5}>
                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontFamily: 'Medium' }}>
                  Loading ...
                </p>
              </Grid>
            )}

            {/* Previous and Next Button */}

            <Grid container spacing={2} mt={2}>
              <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: '#f7f7f7',
                    width: '100%',
                    padding: '5%',
                    color: '#000000',
                    height: '100%',
                    border: '1px solid #ddd'
                  }}
                  onClick={handlePreviousButton}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#002D8C', width: '100%', padding: '5%', color: '#ffffff', height: '100%' }}
                  onClick={handleNextButton}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default DomesticHelperPolicyAddOn;

const DomesticHelperCustomerPolicyAddons = [
  { id: 'sr', label: 'Sr.', minWidth: 50, align: 'center' },
  { id: 'productid', label: 'Product ID', minWidth: 150, align: 'center' },
  { id: 'Prices', label: 'Price', minWidth: 90, align: 'center' },
  { id: 'price', label: 'Action', minWidth: 60, align: 'center' }
];
