import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import useGetPremiumTableData from '../hooks/useGetPremiumTableData';
import OverlayLoader from 'components/loader/OverlayLoader';
import Successmodal from 'components/modals/contentmodal/Successmodal';
import BasicTable from 'pages/dashboard/BasicTable';

const PremiumTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, loading] = useGetPremiumTableData(() => setIsOpen(true));
  const [showLoader, setShowLoader] = useState(true);
  const timeoutDuration = 3000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (loading && showLoader) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="flex-start"
      sx={{
        paddingTop: '24px !important',
        paddingLeft: '24px !important',
        paddingRight: '0px !important',
        paddingBottom: '0px !important'
      }}
    >
      <BasicTable rows={data} columns={columns} searchOn={['Pn', 'pt']} showDatePicker={false} />
      <Successmodal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Download'}
        subtitle={'Download Started'}
        handleCloseModal={handleCloseModal}
      />
    </Grid>
  );
};

export default PremiumTable;
const columns = [
  { id: 'sr', label: 'Sr.', minWidth: 80, align: 'center' },
  { id: 'Pn', label: 'Product Description', minWidth: 700, align: 'center' },

  {
    id: 'Action',
    label: 'Action',
    align: 'left',
    minWidth: 20
  }
];
