import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Footer from '../corporate/customerlandingpage/components/Footer';
import motorvehicle from '../../../assets/images/AndaNewImagesFolder/02.09.2024/ANDA_ProductBanner_Motorcycle.jpg';
import ResponsiveAppBar from '../corporate/customerlandingpage/components/NavBar';
import ImageCard from '../corporate/customerlandingpage/components/ImageCard';
import CalculateMotorVehicleContainer from './CalculateMotorVehicleContainer';
import ProductMotorVehicleTab from './components/ProductMotorVehicleTab';
import useProductPlansData from '../corporate/customerlandingpage/hooks/useProductPlansData';
import PlanPremiumTable from './components/PlanPremiumTable';
import MotorVehicleForm from './components/MotorVehicleForm';
import { useNavigate } from 'react-router-dom';
import { addActiveParams, resetActiveSteps, setShowMotorVehicleForm } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '../../../../node_modules/@mui/material/index';
const MotorVehicle = () => {
  const dispatch = useDispatch();
  const tsaTypeParam = 'Motor';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);
  const showMotorVehicleForm = useSelector((state) => state.persistedReducer.premiumPolicy.showMotorVehicleForm);

  const handleCalculatePremium = (selectedItem) => {
    dispatch(addActiveParams(selectedItem));
    dispatch(setShowMotorVehicleForm(true));
  };

  const handleGoBack = () => {
    dispatch(setShowMotorVehicleForm(false));
  };

  useEffect(() => {
    if (!showMotorVehicleForm) {
      dispatch(resetActiveSteps());
    }
  }, [showMotorVehicleForm, dispatch]);

  const isMobileView = useMediaQuery('(max-width:767px)');
  // const isTabView = useMediaQuery('(min-width:759px) and (max-width:950px)');

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <ResponsiveAppBar />
      <div style={{ position: 'relative', width: '100%', overflow: 'hidden', height: '35vh' }}>
        <img src={motorvehicle} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Motor Vehicle</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored Reliable Always Ready</p>
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 2, marginTop: '-60px', width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            {showMotorVehicleForm ? (
              <MotorVehicleForm handleGoBack={handleGoBack} />
            ) : (
              <CalculateMotorVehicleContainer data={data} handleCalculatePremium={handleCalculatePremium} />
            )}
          </Grid>
        </Grid>
      </div>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        mt={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '78%',
          marginTop: isMobileView ? '25%' : '5%',
          height: 'auto'
        }}
      >
        <ProductMotorVehicleTab />
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <ImageCard />
      </div>
      <Grid item xs={12} md={12} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default MotorVehicle;
