import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../corporate/customerlandingpage/components/NavBar';
import { Grid } from '@mui/material';
import domestic_helper from '../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_ProductBanner_DomesticWorker.jpg';
import ProductDetailVerticalTab from '../components/ProductDetailVerticalTab';
import ImageCard from '../../corporate/customerlandingpage/components/ImageCard';
import Footer from '../../corporate/customerlandingpage/components/Footer';
import DomesticCalculateContainer from '../components/DomesticCalculateContainer';
import useProductPlansData from 'modules/customer/corporate/customerlandingpage/hooks/useProductPlansData';
import DomesticHelperPolicyCoverage from 'modules/customer/domestic helper policy view/pages/DomesticHelperPolicyCoverage';
import { addActiveParams, removeOtherState, resetActiveSteps, setPremiumCalculated } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '../../../../../node_modules/@mui/material/index';

const DomesticHelper = () => {
  const dispatch = useDispatch();
  const tsaTypeParam = 'Domestic Helper';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);
  const isPremiumCalculated = useSelector((state) => state.persistedReducer.premiumPolicy.isPremiumCalculated);

  const handleCalculatePremium = (index) => {
    try {
      const plan = data[index];
      const isCalculated = true;
      dispatch(setPremiumCalculated(isCalculated));
      dispatch(addActiveParams(plan));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isPremiumCalculated) {
      dispatch(resetActiveSteps());
    }
  }, [isPremiumCalculated, dispatch]);

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(min-width:759px) and (max-width:850px)');
  const isLaptop = useMediaQuery('(min-width:851px) and (max-width:950px)');

  // const handleGoBack = () => {
  //   // setShowDomesticHelperForm(false);
  //   store.dispatch(setPremiumCalculated(false));
  // };

  // useEffect(() => {
  //   {
  //     store.dispatch(removeOtherState());
  //     // store.dispatch(resetActiveSteps());
  //     // store.dispatch(setShowMotorVehicleForm(false));
  //     // store.dispatch(setPremiumCalculated(null));
  //   }
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ResponsiveAppBar />
      <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
        <img src={domestic_helper} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Domestic Helper</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>eProduct: Helper Guardian Assurance</p>
        </div>
      </div>

      <div style={{ position: 'relative', zIndex: 2, marginTop: '-60px', width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            {isPremiumCalculated ? (
              <DomesticHelperPolicyCoverage />
            ) : (
              <DomesticCalculateContainer data={data} handleCalculatePremium={handleCalculatePremium} />
            )}
          </Grid>
        </Grid>
      </div>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        mt={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // width: isLaptop ? '74%' : '100%',
          marginTop: '5%',
          height: 'auto',
          width: '78%'
        }}
      >
        <ProductDetailVerticalTab />
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <ImageCard />
      </div>
      <Grid item xs={12} md={12} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default DomesticHelper;
