import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import anda_logo from '../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import { useMediaQuery } from '@mui/material';
import tickImage from '../../../../assets/images/product_plan_image/CoverageTickImage.png'; // Replace with your actual path
import crossImage from '../../../../assets/images/product_plan_image/CoverageCrossImage.png'; // Replace with your actual path

const headerData = [{ title: 'Comprehensive' }, { title: 'TPFT' }, { title: 'TPO' }];

export default function CoveragesPlanTableMotorVehicle(props) {
  // const isMobile = useMediaQuery('(max-width:600px)');
  // const isTab = useMediaQuery('(min-width:601px) and (max-width:1380px)');
  const { width, bottomButtons, rows } = props;

  const renderCellContent = (value) => {
    if (value === 'tick') {
      return <img src={tickImage} alt="tick" style={{ width: '30px', height: '30px' }} />;
    }
    if (value === 'cross') {
      return <img src={crossImage} alt="cross" style={{ width: '30px', height: '30px' }} />;
    }
    return null;
  };

  // const isMobileRes = useMediaQuery('(min-width:320px) and (max-width:400px)');
  // const isMobileBtnTab = useMediaQuery('(min-width:401px) and (max-width:600px)');
  // const isTabRes = useMediaQuery('(min-width:601px) and (max-width:1024px)');
  // const isDesktop = useMediaQuery('(min-width:1025px) and (max-width:1440px)');

  const isMobileBtnTab = useMediaQuery('(max-width:400px)');
  const isMobile = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isTab = useMediaQuery('(min-width:601px) and (max-width:900px)');
  const isDesktop = useMediaQuery('(min-width:1025px) and (max-width:1440px)');

  return (
    <TableContainer
      component={Paper}
      style={{
        width: isMobileBtnTab ? '280px' : isMobile ? '90%' : isTab ? '550px' : props.width, // Set width for mobile
        margin: 'auto',
        borderRadius: '15px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        overflowX: isMobileBtnTab || isMobile ? 'scroll' : 'hidden', // Add scroll for mobile
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#012D8C' }}>
            <TableCell
              align="left"
              style={{
                padding: '1%',
                alignItems: 'center',
                width: isMobile ? '100%' : isMobileBtnTab ? '200px' : isDesktop ? '700px' : '420px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Logo */}
                <img
                  src={anda_logo}
                  alt="ANDA logo"
                  style={{
                    width: '70px',
                    filter: 'brightness(0) invert(1)',
                    mixBlendMode: 'screen',
                    marginRight: '4px'
                  }}
                />
                {/* Text next to the logo */}
                {isMobile || isMobileBtnTab ? (
                  <></>
                ) : (
                  <Typography variant="h6" style={{ marginBottom: 2, fontFamily: 'Aptos Italic', fontSize: '13px', color: '#ffffff' }}>
                    Motor Insurance
                  </Typography>
                )}
              </div>
              <Typography variant="body2" style={{ color: '#ffffff', fontFamily: 'Aptos Italic', fontSize: '13px' }}>
                underwritten by Etiqa
              </Typography>
            </TableCell>
            {headerData.map((header, index) => (
              <TableCell
                key={index}
                align="left"
                style={{
                  color: '#FFFFFF',
                  width: '100px' // Fixed width for Basic, Silver, and Platinum
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography style={{ fontSize: '16px' }}>{header.title}</Typography>
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
              }}
            >
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{
                  padding: '1%',
                  width: isMobile ? '350px' : 'auto', // Wider for name column,
                  fontSize:'13px'
                }}
              >
                {row.name}
              </TableCell>
              <TableCell style={{ width: '150px', padding: '4px 8px',fontSize:'13px' }} align="center">
                {renderCellContent(row.basic)}
              </TableCell>
              <TableCell style={{ width: '150px', padding: '4px 8px',fontSize:'13px' }} align="center">
                {renderCellContent(row.silver)}
              </TableCell>
              <TableCell style={{ width: '150px', padding: '4px 8px',fontSize:'13px' }} align="center">
                {renderCellContent(row.platinum)}
              </TableCell>
            </TableRow>
          ))}
          {props.bottomButtons ? (
            <TableRow
              style={{ backgroundColor: '#012D8C', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', padding: '2%' }}
            >
              <TableCell></TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px',fontSize:'13px' }}>Buy Now</button>
              </TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px',fontSize:'13px' }}>Buy Now</button>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CoveragesPlanTableMotorVehicle.defaultProps = {
  width: '100%',
  bottomButtons: false,
  rows: [
    { name: 'Accidental loss or damage by fire', basic: 'tick', silver: 'tick', platinum: 'cross' },
    { name: 'Loss or damage by theft', basic: 'tick', silver: 'tick', platinum: 'cross' },
    { name: 'Accidental loss or damage by other insured causes', basic: 'tick', silver: 'cross', platinum: 'cross' },
    { name: 'Vehicle stolen outside Singapore subject to applicable excess', basic: 'tick', silver: 'tick', platinum: 'cross' },
    { name: 'Death or bodily injury to third party', basic: 'tick', silver: 'tick', platinum: 'tick' },
    { name: 'Loss or damage to third party property (up to $500,000)', basic: 'tick', silver: 'tick', platinum: 'tick' },
    { name: 'Legal costs and expenses', basic: 'tick', silver: 'tick', platinum: 'tick' },
    { name: 'Towing to repairers or places of safety following accident', basic: 'tick', silver: 'tick', platinum: 'cross' }
  ]
};
