import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { downloadPdf, getUserId } from 'helper/utils';
import { store } from 'store/index';
import { addActiveParams, removeAllRenewalData, removeOtherState } from 'store/reducers/premiumPolicy';
import { showDanger, showWarning } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

export default function useGetPremiumTableData(onDownload) {
  const { makeRequest,modal } = useApiCall();
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const handleCalculatePremium = (item) => {
    store.dispatch(removeAllRenewalData());
    store.dispatch(removeOtherState());
    store.dispatch(addActiveParams(item));
    navigate('/dashboard/PremiumCalculateView', { state: { data: { item } } });
  };

  // const handleDownloadPdf = (item) => {
  //  downloadPdf({ url: item?.displayUrl, key: item?.productID });
  //   // downloadPdf({ url: 'https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf', key: item?.productID });
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }

        const UserId = await getUserId();
        const params = { UserId };
        const res = await makeRequest({ url: API.PackagesAndPremiumGet, params });

        if (res?.code === 200) {
          const mappedData = res.data?.map((item) => ({
            sr: `${item?.srNo}`,
            Pn: `${item?.productName}`,
            Action: (
              <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'center' }}>
                <button
                  style={{
                    backgroundColor: '#4cb748',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    cursor: 'pointer',
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '1%',
                    fontFamily: 'Medium',
                    border: 'none'
                  }}
                  onClick={() => handleCalculatePremium(item)}
                  aria-label="Purchase"
                >
                  <VisibilityIcon style={{ fontSize: '15px' }} />
                  Purchase
                </button>
                {/* <button
                  style={{
                    backgroundColor: '#0174BF',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    marginLeft: '10px',
                    cursor: 'pointer',
                    width: '90px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '3px',
                    fontFamily: 'Medium',
                    border: 'none'
                  }}
                  onClick={() => handleDownloadPdf(item)}
                  aria-label="Download"
                >
                  <SimCardDownloadOutlinedIcon style={{ fontSize: '15px' }} />
                  Download
                </button> */}
                
                <a
                  href={item?.displayUrl}
                  download={item?.productID}
                  target="_blank"
                  onClick={(e) => {
                    if (!item?.displayUrl || item.displayUrl.trim() === "") {
                      e.preventDefault();
                      showWarning('PDF URL is not available.');
                    }
                  }}
              style={{
                      backgroundColor: '#0174BF',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    marginLeft: '10px',
                    cursor: 'pointer',
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '3px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontFamily: 'Medium',
                    textDecoration: 'none'
                  }}
                  aria-label="Download"
                >
                  <SimCardDownloadOutlinedIcon style={{ fontSize: '14px' }} />
                  Download
                </a>
              </div>
            )
          }));

          setData(mappedData);
          setIsLoading(false);
        } else if (res?.code === 404) {
          setIsLoading(false);
          showWarning('No Data found');
        }
      } catch (e) {
        setIsLoading(false);
        console.error('Error in fetching premium data', e);
      }
    };

    fetchData();
  }, [network]);

  return [data, loading];
}
