import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Grid } from '../../../../../node_modules/@mui/material/index';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import DomesticHelperPlansTable from 'modules/customer/domestic helper policy view/components/DomesticHelperPlansTable';
import CoveragesPlanTableMotorVehicle from 'modules/customer/domestic helper policy view/components/CoveragesPlanTableMotorVehicle';
const pointData1 = [
  {
    heading1: 'There are several factors that influence motorcycle insurance premiums. Some key factors include:',
    heading: 'Age ',
    description: 'Older drivers generally pay lesser premiums as compared to young drivers due to risk profiling by Insurers.'
  },
  // {

  // },
  {
    heading: 'Driving Experience ',
    description:
      'Experienced drivers with no claims will typically pay lesser premiums as compared young and inexperienced drivers as they present a lower risk. '
  },
  // {
  //   img: require('../../../../assets/images/motortab2.png'),
  //   heading: 'Traffic Offences ',
  //   description:
  //     'Avoiding points on your licence helps keep your premiums low, as convicted drivers are considered a higher risk, thus incurring higher premiums. '
  // },
  {
    heading: 'Prior Claims ',
    description:
      'Have no prior records of any claims, together with No Claims Discount (NCD), would reduce your motor insurance premiums.  '
  },
  {
    heading: 'Additional Drivers ',
    description:
      'Insurer may charge additional premiums for request to add named drivers to your policy. Should the named driver have any adverse claim records, this may also result in additional premium loading by the Insurer.'
  },
  {
    heading: 'Occupation ',
    description:
      'Some professions are considered by Insurers as a higher risk than others. For example, the Insurer may consider a food delivery driver as a higher risk profile due to the longer periods of time on the road. '
  },
  {
    heading: 'Engine size',
    description:
      'The specifications of the motor vehicle (for example, the vehicle make and model, and engine capacity) could influence the cost of your motor insurance policy - generally speaking, motor vehicles with a higher engine capacity are pricier to insure. '
  }
];
const pointData = [
  {
    heading: 'Comprehensive Coverage ',
    description:
      'The most superior form of coverage as well as the most common. This covers the areas mentioned under TPFT (Third Party, Fire and Theft) and TPO (Third Party Only) policies and offers protection to the vehicle itself. ',
    description2:
      'In the event of an accident or insured event, there will be an excess payable by the insured, after which the Comprehensive plan will pay based on the vehicle’s market value for the damages incurred at the time of loss. ',
    description3:
      'If the motorcycle is under a loan financing, the financing company or bank will usually require a comprehensive insurance to be purchased for the vehicle, to protect their asset where they have legal ownership until the loan is fully paid. '
  },
  {
    heading: 'Third Party, Fire and Theft (TPFT) ',
    description:
      'This plan type provides slightly more coverage as compared to TPO policies. Besides the third-party liabilities mentioned under TPO coverage, a TPFT plan also insures your vehicle for events such as Fire or Theft.',
    description2:
      'If you regularly drive outside of Singapore where theft frequencies may be higher, a TPFT coverage may be the minimal coverage you might wish to consider.'
  },
  {
    heading: 'Third Party Only (TPO) ',
    description:
      'A Third Party Only policy offers the least coverage and is also the most affordable option. This plan offers protection in situations where you may be held responsible for an accident, for example either third party property damages or third-party bodily injuries.',
    description2:
      'These third-party liabilities include damages to any property involved during the accident – including vehicles, trees, or even lamp posts, as well as medical and hospitalisation expenses or other injuries sustained during the accident by the third party person(s). This also includes loss of wages and loss of use claim from the third party. '
  }
];
const faqsData = [
  {
    heading: 'What is Motorcycle Insurance? ',
    description:
      'Having motorcycle insurance offers you protection while you are on the road. motorcycle insurance could cover or lower your costs of motorcycle damage. It encompasses everything from your motorcycle encountering falling objects to getting stolen or vandalised. The cases when the motorcycle catches fire or is damaged because of floods and lightning, are accounted for too. Not only this, but the insurance also covers any third-party injuries or property damage if the driver is liable for it. '
  },
  {
    heading: 'Do I need Motorcycle Insurance?',
    description:
      'All vehicles are legally required to have insurance coverage to be driven in Singapore. Motorcycle insurance is a prerequisite before you can renew your road tax. Your insurance must cover the entire road tax renewal period (usually a full year) before you can renew. The insurance must at least cover third-party liability such as deaths and bodily injury injuries and property damages. This is important so that victims of a traffic accident are covered by insurance',

    description9:
      'It is an offence to use a vehicle without valid insurance coverage. The penalty for this offence includes a fine of up to $1,000 or imprisonment of up to 3 months or both, and a mandatory disqualification from holding a driving licence for at least 12 months.'
  },
  {
    heading: 'Do I need special insurance if I use my motorcycle for food delivery?',
    description:
      'If you work for food delivery companies such as GrabFood, Deliveroo, or FoodPanda, your motorcycle insurance must have the relevant coverage. It could be in the form of an extension to the policy, or a Commercial motorcycle insurance policy.',
    description1:
      'When applying for motorcycle insurance, it is important to declare the usage of your motorcycle upfront, so that the insurance will cover you appropriately. If not, you will find yourself not covered in the event of an accident claim, or your application for a food delivery job rejected. '
  },
  {
    heading: 'What Is Excess In Motorcycle Insurance?',
    description:
      'Excess refers to the amount of money that the policyholder must pay out-of-pocket towards a claim before the insurer will cover the remaining cost.',
    description1: 'Additional excess may apply for Motorcycle Insurance policies with Delivery Extension coverage taken up.'
  },
  {
    heading: 'What is not Covered In Motorcycle Insurance Policies?',
    description: 'What is not covered in insurance are not limited to the following: ',
    point1: 'Wear and tear',
    point2: 'Depreciation ',
    point3: 'Mechanical breakdown ',
    point4: 'Negligence',
    point5: 'Damage due to illegal activities (driving under the influence, road racing) ',
    point6: 'Damage caused by drivers without a valid license ',
    point7: 'Damage due to overloading ',
    point8: 'Damage due to intentional acts  ',
    point9: 'Damage due to unapproved modifications '
  },
  {
    heading: 'What to do in the event of a motor accident? ',
    point1:
      'Exchange Particulars of Parties Involved in the Accident and take note of the vehicle numbers. Take photos of the scene of the accident and the damages to all vehicles.',
    point2: 'Report the accident to the Insurer within 24 Hours or by the next working day. ',
    point3: 'Call ANDA at 6534 288 for further advice on the accident reporting procedure.',
    description3: 'If you do not report an accident: ',
    point4: 'The Insurer may repudiate liability; ',
    point5: 'Your No-Claims Discount may be reduced upon the renewal of your policy; and  ',
    point6: 'The Insurer may cancel or refuse to renew your policy. '
  },
  {
    heading: 'When do I need to make a police report?  ',
    description: 'For motor accident involving any of the following:',
    // description1: 'Make a Traffic Police report as soon as possible or within 24 hours of the accident if the accident involves: ',
    point1: 'Injury cases ',
    point2: 'Accidents involving a government vehicle or damage to government property ',
    point3: 'Accidents involving a foreign vehicle',
    point4: 'Accidents involving a pedestrian or cyclist',
    point5: '“Hit and Run” cases ',
    point6: 'Vandalism '
  },
  {
    heading: 'What is the Geographical Area covered under my policy? ',
    description: 'The geographical coverage of your motor insurance will be stated out in the Policy Wordings, for example:  ',
    description1:
      'The Republic of Singapore, West Malaysia, and that part of Thailand within 50 miles or 80km of the border between Thailand and West Malaysia.',
    description9: 'Insurer may also impose additional excess for coverage on accident or theft of the insured vehicle. '
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const rows = [
  { name: 'Co-Payment', basic: 'cross', silver: 'tick', platinum: 'tick' },
  { name: 'Letter of Gurantee', basic: 'tick', silver: 'cross', platinum: 'cross' },
  { name: 'Accidental Death/Permanent Disablement', basic: 'cross', silver: 'tick', platinum: 'cross' },
  { name: 'Medical Expenses', basic: 'cross', silver: 'tick', platinum: 'tick' },
  { name: 'Repatriation Expenses', basic: 'tick', silver: 'tick', platinum: 'cross' },
  { name: 'Hospitalisation & Surgical Expenses (Per Year)', basic: 'tick', silver: 'tick', platinum: 'tick' },
  { name: 'Wages Reimbursement', basic: 'cross', silver: 'cross', platinum: 'cross' },
  { name: 'Re-hiring Expenses', basic: 'tick', silver: 'tick', platinum: 'tick' },
  { name: "Domestic Worker's Liability", basic: 'cross', silver: 'cross', platinum: 'tick' },
  { name: 'Special Grant', basic: 'cross', silver: 'cross', platinum: 'cross' }
];

export default function ProductMotorVehicleTab() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(min-width:759px) and (max-width:850px)');
  const isLaptop = useMediaQuery('(min-width:851px) and (max-width:950px)');
  // const [openIndices, setOpenIndices] = React.useState([]);
  // const [openIndicesFactor, setOpenIndicesFactor] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState([]);

  // const handleClick = (index) => {
  //   setOpenIndices(
  //     (prevOpenIndices) =>
  //       prevOpenIndices.includes(index)
  //         ? prevOpenIndices.filter((i) => i !== index) // Remove index if it's already open
  //         : [...prevOpenIndices, index] // Add index if it's not open
  //   );
  // };
  // const handleClickFactor = (index) => {
  //   setOpenIndicesFactor(
  //     (prevOpenIndices) =>
  //       prevOpenIndices.includes(index)
  //         ? prevOpenIndices.filter((i) => i !== index) // Remove index if it's already open
  //         : [...prevOpenIndices, index] // Add index if it's not open
  //   );
  // };
  const handleToggle = (index) => {
    if (activeIndex.includes(index)) {
      // If the index is already in the array, remove it (close the item)
      setActiveIndex(activeIndex.filter((i) => i !== index));
    } else {
      // If the index is not in the array, add it (open the item)
      setActiveIndex([...activeIndex, index]);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: isMobile ? '0%' : '1.9%' }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Summary of Coverage"
          {...a11yProps(0)}
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#05318e ' : '#86878c',
            fontFamily: 'Medium',
            fontSize: '16px',
            fontWeight: value === 0 ? '600' : '500',
            border: value === 0 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '280px' : isTab ? '100%' : '300px',
            padding: '5px'
          }}
        />
        <Tab
          label="Types of Motor Insurance"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#05318e ' : '#86878c',
            fontFamily: 'Medium',
            fontSize: '16px',
            fontWeight: value === 1 ? '600' : '500',
            border: value === 1 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '280px' : isTab ? '100%' : '300px',
            padding: '5px'
          }}
        />
        <Tab
          label="Factors determining Motor Insurance"
          {...a11yProps(2)}
          sx={{
            bgcolor: value === 2 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 2 ? '#05318e ' : '#86878c',
            fontFamily: 'Medium',
            fontSize: '16px',
            fontWeight: value === 2 ? '600' : '500',
            border: value === 2 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '280px' : isTab ? '100%' : '300px',
            padding: '5px'
          }}
        />
        <Tab
          label="FAQs"
          {...a11yProps(3)}
          sx={{
            bgcolor: value === 3 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: isMobile ? 'center' : 'start',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 3 ? '#05318e ' : '#86878c',
            fontFamily: 'Medium',
            fontSize: '16px',
            fontWeight: value === 3 ? '600' : '500',
            border: value === 3 ? '1px solid #05318e !important' : '1px solid #000000',
            width: isMobile ? '100%' : isTab ? '100%' : isLaptop ? '300px' : '300px',
            padding: '5px'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div
            style={{
              width: '100%',
              // marginTop: '2%',
              marginLeft: isMobile ? '0%' : '2%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {/* <DomesticHelperPlansTable width={'100%'} rows={rows} /> */}
            <CoveragesPlanTableMotorVehicle width={'100%'} />
            {/* <CoveragesDetailsTable /> */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {pointData.map((item, index) => (
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  {/* Arrow Icon - Right if closed, Down if open */}
                  {/* {openIndices.includes(index) ? (
                    <ArrowDropDownIcon sx={{ color: '#002d8c', marginRight: '8px' }} />
                  ) : (
                    <ArrowRightIcon sx={{ color: '#002d8c', marginRight: '8px' }} />
                  )} */}

                  {/* Heading */}
                  <Typography sx={{ color: '#002d8c', fontSize: '16px', fontWeight: '500', fontFamily: 'Medium', mb: 0.5 }}>
                    {item.heading}
                  </Typography>
                </div>

                {/* Show description when the heading is open */}
                {/* {openIndices.includes(index) && ( */}
                {/* <> */}
                <Typography sx={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
                  {item.description}
                </Typography>
                <Typography sx={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%', marginTop: '6px' }}>
                  {item.description2}
                </Typography>
                <Typography sx={{ fontSize: '13px', fontWeight: '500', fontFamily: 'Medium', width: '100%', marginTop: '6px' }}>
                  {item.description3}
                </Typography>
                {/* </> */}
                {/* )} */}
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {pointData1.map((item, index) => (
              <span key={index}>
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    fontFamily: 'Medium',
                    marginBottom: '4px'
                    // cursor: 'pointer' // Makes it clear that the heading is clickable
                  }}
                >
                  {item.heading1}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* {openIndicesFactor.includes(index) ? (
                    <ArrowDropDownIcon style={{ marginRight: '8px', color: '#002d8c' }} />
                  ) : (
                    <ArrowRightIcon style={{ marginRight: '8px', color: '#002d8c' }} />
                  )} */}
                  <Typography
                    style={{
                      color: '#002d8c',
                      fontSize: '16px',
                      fontFamily: 'Medium',
                      marginBottom: '2px',
                      cursor: 'pointer'
                    }}
                    // onClick={() => handleClickFactor(index)}
                  >
                    {item.heading}
                  </Typography>
                </div>

                {/* Show or hide the description based on whether the index is open */}
                {/* {openIndicesFactor.includes(index) && ( */}
                <Typography
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    fontFamily: 'Medium',
                    marginBottom: '12px'
                  }}
                >
                  {item.description}
                </Typography>
                {/* )} */}
              </span>
            ))}
          </Grid>
        </TabPanel>{' '}
        <TabPanel value={value} index={3}>
          <div>
            {faqsData.map((faq, index) => (
              <div key={index}>
                <div
                  onClick={() => handleToggle(index)}
                  style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', cursor: 'pointer' }}
                >
                  {activeIndex.includes(index) ? (
                    <ArrowDropDownIcon style={{ marginRight: '8px', color: '#002d8c' }} />
                  ) : (
                    <ArrowRightIcon style={{ marginRight: '8px', color: '#002d8c' }} />
                  )}
                  <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>{faq?.heading}</Typography>
                </div>

                {activeIndex.includes(index) && (
                  <div>
                    <p style={{ marginLeft: '4%', fontSize: '13px' }}>{faq.description}</p>
                    <p style={{ marginLeft: '4%', fontSize: '13px' }}>{faq.description1}</p>
                    <p style={{ marginLeft: '4%', fontSize: '13px' }}>{faq.description9}</p>

                    {/* Render points if available */}
                    {faq.point1 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`1. ${faq.point1}`}</p>}
                    {faq.point2 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`2. ${faq.point2}`}</p>}
                    {faq.point3 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`3. ${faq.point3}`}</p>}
                    {faq.point4 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`4. ${faq.point4}`}</p>}
                    {faq.point5 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`5. ${faq.point5}`}</p>}
                    {faq.point6 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`6. ${faq.point6}`}</p>}
                    {faq.point7 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`7. ${faq.point7}`}</p>}
                    {faq.point8 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`8. ${faq.point8}`}</p>}
                    {faq.point9 && <p style={{ marginLeft: '4%', fontSize: '13px' }}>{`9. ${faq.point9}`}</p>}
                  </div>
                )}
              </div>
            ))}
          </div>
        </TabPanel>
      </Box>
    </Box>
  );
}
