import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../components/NavBar';
import sliderpicture1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_HomepageBanner.png';
import Products from '../components/Products';
import { Grid } from '@mui/material';
import Footer from 'modules/customer/corporate/customerlandingpage/components/Footer';
import ImageCard from '../components/ImageCard';
import ButtonGrid from 'modules/customer/corporate/customerlandingpage/components/ButtonGrid';
import ImageTabProductPlans from '../components/ImageTabProductPlans';
import AboutAndaInsurance from '../components/AboutAndaInsurance';
import { store } from 'store/index';
import {
  removeOtherState,
  resetActiveSteps,
  setPremiumCalculated,
  setPremiumCalculatedForeignWorker,
  setShowMotorVehicleForm
} from 'store/reducers/premiumPolicy';
import { useSelector } from 'react-redux';
import ContactUs from '../components/ContactUs';
import TermsOfUse from '../components/TermsOfUse';
import PrivacyPolicy from '../components/PrivacyPolicy';

const CustomerLandingPage = () => {
  const [showContactUs, setShowContactUs] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const activeSteps = useSelector((state) => state.persistedReducer.premiumPolicy.activeSteps);

  useEffect(() => {
    store.dispatch(removeOtherState());
    store.dispatch(resetActiveSteps());
    store.dispatch(setShowMotorVehicleForm(false));
    store.dispatch(setPremiumCalculated(false));
    store.dispatch(setPremiumCalculatedForeignWorker(false));

    window.history.replaceState(null, null, window.location.pathname);

    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    window.onpopstate = function () {
      window.history.go(1);
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);
  const handleContactClick = () => {
    setShowContactUs(true);
  };
  const handleTermsOfUseClick = () => {
    setShowTermsOfUse(true);
  };

  return (
    <>
      <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}>
        <ResponsiveAppBar />
        {showContactUs ? (
          <Grid item xs={12} md={12} lg={12} mt={5} sx={{ width: '100%' }}>
            <ContactUs />
          </Grid>
        ) : showTermsOfUse ? (
          <Grid item xs={12} md={12} lg={12} mt={5} sx={{ width: '100%' }}>
            <TermsOfUse />
          </Grid>
        ) : showPrivacyPolicy ? (
          <Grid item xs={12} md={12} lg={12} mt={5} sx={{ width: '100%' }}>
            <PrivacyPolicy />
          </Grid>
        ) : (
          <>
            <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
              <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              <div
                style={{
                  position: 'absolute',
                  top: '20%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',

                  color: 'white',
                  zIndex: 1,
                  width: '100%'
                }}
              >
                <p
                  style={{
                    fontSize: '30px',
                    fontFamily: 'Medium',
                    marginBottom: '3px',

                    width: '100%'
                  }}
                >
                  Beyond Coverage, Beyond Compare
                </p>
                <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored, Reliable, Always Ready</p>
              </div>
            </div>
            <Grid item xs={12} md={12} lg={12} mt={3}>
              <ImageTabProductPlans />
            </Grid>
            <Grid item xs={12} mb={6}>
              <AboutAndaInsurance />
            </Grid>
            <Grid item xs={12} mb={5} mt={2}>
              <Products />
            </Grid>
            <Grid item xs={12} md={12} lg={12} mb={3} mt={3}>
              <ButtonGrid />
            </Grid>

            <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <ImageCard />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ width: '100%' }}>
              <Footer />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
export default CustomerLandingPage;
