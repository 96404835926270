import ReusableCard from 'components/cards/ResuableCard';
import React from 'react';
import { Grid, Button, TextField, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { useSelector, useDispatch } from 'react-redux';
import OverlayLoader from 'components/loader/OverlayLoader';
// import { Typography } from '../../../../../node_modules/@mui/material/index';

const DomesticHelperPremiumAmount = ({ handleNextButton, handlePreviousButton, loading }) => {
  const navigate = useNavigate();
  // const { data, error: getError } = useGetFWDHCalculationQuery(productId);
  const { premiumCalculation } = useSelector((state) => state?.persistedReducer?.premiumPolicy);

  return (
    <div>
      {premiumCalculation && <OverlayLoader show={loading} />}
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'5%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '700' }}>Premium Summary of Domestic Helper Insurance</Typography>
            </Grid>

            <Grid container p={1} xs={12} style={{ border: '2px solid  #002d8c', marginTop: '5%', borderRadius: '10px' }}>
              <Grid item xs={6}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Premium Without GST
                </p>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  {`$ ${(premiumCalculation?.premiumAmount).toFixed(2)}`}
                </p>
              </Grid>
              <Grid item xs={6}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Add-on total
                </p>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  {`$ ${(premiumCalculation?.addOnAmount).toFixed(2)}`}
                </p>
              </Grid>
              <Grid item xs={6}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  GST ( 9% )
                </p>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  {`$ ${(premiumCalculation?.gstAmount).toFixed(2)}`}
                </p>
              </Grid>
              <Grid item xs={6}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: '600', marginTop: '0px', marginBottom: '0px' }}>
                  Premium with GST
                </p>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ fontFamily: 'Medium', fontSize: '13px', fontWeight: 'bold', marginTop: '0px', marginBottom: '0px' }}>
                  {`$ ${(premiumCalculation?.premiumWithGST).toFixed(2)}`}
                </p>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: '#f7f7f7',
                    width: '100%',
                    padding: '5%',
                    color: '#000000',
                    height: '100%',
                    border: '1px solid #ddd'
                  }}
                  onClick={handlePreviousButton}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#002D8C', width: '100%', padding: '5%', color: '#ffffff', height: '100%' }}
                  onClick={handleNextButton}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default DomesticHelperPremiumAmount;

const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };
