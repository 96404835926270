import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, InputLabel, TextField } from '@mui/material';
import ReusableCard from 'components/cards/ResuableCard';
import OnwerRiderDetails from './OnwerRiderDetails';
import AdditionalRiderDetails from './AdditionalRiderDetails';
import { useSelector } from 'react-redux';
import { showWarning, showDanger } from 'components/nudges/Alert';
import { getUserId, getLoginDetailsId } from 'helper/utils';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { useNavigate } from 'react-router-dom';
import OverlayLoader from 'components/loader/OverlayLoader';
import { clearCustomerRiderData } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';

const ContainerContent = ({ handleNextButton, handlePreviousButton }) => {
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { CustomerriderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);

  const { activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const isCompanyRegistered = otherState.isCompanyRegistered;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const network = useNetworkStatus();

  const findTheYoungest = () => {
    try {
      let d = Object.values(CustomerriderDataSection);
      console.log('dssssssssssssssssss', d);
      d.sort((a, b) => compareDates(a.age, b.age));
      console.log('Youngest Person:', d[0]);
      return d[0] || null;
    } catch (e) {
      console.error('Error during figure out the youngest person', e);
    }
  };

  useEffect(() => {
    if (isCompanyRegistered) {
      store.dispatch(clearCustomerRiderData());
    }
  }, [isCompanyRegistered]);

  useEffect(() => {
    const hasRiders = Object.keys(CustomerriderDataSection).length > 0;
    setIsButtonDisabled(!hasRiders);
  }, [CustomerriderDataSection]);

  // const findTheYoungest = () => {
  //   try {
  //     const riders = Object.values(CustomerriderDataSection);
  //     const riderAges = riders.map((rider) => parseInt(rider.age, 10));
  //     const ownerAge = parseInt(otherState.ownerAge, 10);
  //     return Math.min(ownerAge, ...riderAges);
  //   } catch (e) {
  //     console.error('Error during finding the youngest person', e);
  //     return null;
  //   }
  // };
  const compareDates = (date1, date2) => {
    return new Date(date1) - new Date(date2);
  };

  const handleClick = async () => {
    try {
      let userId = await getUserId();
      let loginDetailsId = await getLoginDetailsId();
      let mergedStates = { ...otherState };
      let ncd = mergedStates?.NCD;
      let selectedOwner = Object.values(CustomerriderDataSection).find((owner) => owner.isCheckboxChecked);
      let selectedOwnerRider = Object.values(CustomerriderDataSection).find((owner) => owner.isRiderCheckboxChecked);
      let ownerGender = selectedOwner?.gender || Object.values(CustomerriderDataSection).find((rider) => rider.gender)?.gender;

      let { claimamount, isFoodParcel, claimcount } = otherState;
      const currentDate = new Date();
      // const dobYear = new Date(ownerDob).getFullYear();
      // const licenseDate = new Date(ownerLicense);

      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      if (Object.values(CustomerriderDataSection).length <= 0) {
        showWarning('Please Add Owner / Rider to continue!');
        return;
      }
      if (otherState?.isFoodParcel && otherState.age > 65) {
        showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
        return;
      }
      if (!otherState?.isFoodParcel && otherState.age > 75) {
        showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
        return;
      }
      if (claimcount === 1 && claimamount > 20000 && !isFoodParcel) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      if (claimcount === 1 && claimamount > 15000 && isFoodParcel) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }

      if (claimcount === 2) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      // let youngestAge = findTheYoungest();

      // let ridingExperience;
      // if (!isCompanyRegistered) {
      //   if (mergedStates.ownerExperience) {
      //     ridingExperience = mergedStates.ownerExperience;
      //   } else {
      //     ridingExperience = '13 months';
      //   }
      // }
      // let countLessthan1YearExp = 0;
      // let riderCount = 0;

      // Object.values(CustomerriderDataSection).forEach((rider) => {
      //   const totalMonths = parseInt(rider.ownerExperience, 10);
      //   if (totalMonths < 12) {
      //     countLessthan1YearExp++;
      //   }
      //   riderCount++;
      // });

      let riderAge;
      let ridingExperience;
      let young;
      if (!isCompanyRegistered) {
        young = findTheYoungest();
        riderAge = parseInt(young?.age) || 0;
        if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
          ridingExperience = selectedOwner.experience;
        } else {
          ridingExperience = '13 months';
        }
      }
      let countLessthan1YearExp = 0;
      let riderCount = 0;

      Object.values(CustomerriderDataSection).forEach((rider) => {
        const isOwner = rider === selectedOwner;
        const isOwnerRider = rider === selectedOwnerRider;

        if (!isOwner) {
          const totalMonths = parseInt(rider.experience, 10);
          if (totalMonths < 12) {
            countLessthan1YearExp++;
          }
          riderCount++;
        }
      });

      if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
      }

      if (isCompanyRegistered) {
        riderCount = 0;
      }

      if (
        selectedOwner &&
        selectedOwner.isRiderCheckboxChecked &&
        riderCount === 2 &&
        Object.values(CustomerriderDataSection).length === 3
      ) {
        riderCount = 3;
      }

      if (Object.values(CustomerriderDataSection).length === 1 && selectedOwner && !selectedOwnerRider) {
        showWarning('At least one rider must be selected to proceed if the owner is not riding the vehicle.');
        return;
      }
      setIsLoading(true);
      let body = {
        userId: userId || null,
        productMasterID: activeParams?.productID,
        businessType: mergedStates.businessType,
        vehicleId: mergedStates?.vehicleId || '',
        capacity: mergedStates?.vehicleCapacity || '',
        ageOfMotorCycle: mergedStates?.ageOfVehicle || '',
        isCompanyRegisted: mergedStates?.isCompanyRegistered,
        isFoodDelivery: mergedStates?.isFoodParcel,
        riderAge: riderAge,
        ridingExperience: ridingExperience,
        gender: ownerGender || '',
        claimsIn3Year: mergedStates?.claimcount || '0',
        amountOfClaimsIn3Year: mergedStates?.claimamount || '0',
        ncdPercentage: ncd.replace('%', '') || 0,
        riderCount: riderCount,
        vehicleRegistrationNumber: mergedStates?.vehicleRegistration || '',
        chassisNumber: mergedStates?.chasisNumber || '',
        engineNumber: mergedStates?.engineNumber || '',
        nameofHirePurchase: mergedStates?.nameOfHirePurchase || '',
        claimsDetails: '0',
        registrationDate: mergedStates?.RegistrationDate || '',
        loginDetailsId: loginDetailsId || 0,
        poiStartDate: mergedStates?.POIStartDate || '',
        poiEndDate: mergedStates?.POIEndDate || '',
        uen: mergedStates?.UEM || '',
        companyName: mergedStates?.cm || '',
        email: mergedStates?.email || '',
        phone: mergedStates?.phone || '',
        countLessthan1YearExp: countLessthan1YearExp
      };
      //console.log('Final Body --->', body);
      let res = await makeRequest({ method: 'POST', url: API.PackagesAndPremiumCalculation, body });
      //console.log('response received from premium policy calculation --->', res);

      if (res?.code === 200) {
        setIsLoading(false);
        handleNextButton(res.data);
      } else {
        showDanger(res?.message);
        setIsLoading(false);
      }
    } catch (e) {
      showDanger(e);
      setIsLoading(false);
    }
  };
  return (
    <Grid container>
      <OverlayLoader show={isLoading} />
      <Grid item xs={12} sm={12} md={12} lg={12} className="inputContainer">
        <div className="inputLabel" fontSize="small">
          <InputLabel style={{ fontSize: '13px', marginBottom: '10px', fontFamily: 'Medium', color: '#636468' }}>Product name</InputLabel>
        </div>
        <TextField
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="commonInput"
          value={activeParams?.productName}
          disabled
          // onChange={(e) => setFirstName(e.target.value)}
          required
          onClick={(e) => e.stopPropagation()}
          inputProps={{
            style: {
              fontSize: '13px', // Change the font size of the input text
              marginLeft: '2px'
            }
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={12} mt={2}>
        <OnwerRiderDetails />
      </Grid> */}
      <Grid item xs={12} md={12} style={{ marginTop: '2%' }}>
        <AdditionalRiderDetails />
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
          <Button
            size="small"
            variant="contained"
            style={{
              backgroundColor: '#f7f7f7',
              width: '100%',
              padding: '5%',
              color: '#000000',
              height: '100%',
              border: '1px solid #ddd'
            }}
            onClick={handlePreviousButton}
          >
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} md={2.5} sm={6} lg={2.5}>
          <Button
            size="small"
            variant="contained"
            style={{
              backgroundColor: '#002D8C',
              width: '100%',
              padding: '5%',
              color: '#ffffff',
              height: '100%'
            }}
            onClick={handleClick}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotorVehicleRidingExperienceContainer = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <ReusableCard
      backgroundColor="#fff"
      border="1px solid #ddd"
      padding="3%"
      borderRadius={15}
      content={<ContainerContent handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />}
    />
  );
};

export default MotorVehicleRidingExperienceContainer;
